import React, { Component } from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Paper from "@material-ui/core/Paper"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import { get } from "lodash"
import { FormattedMessage } from "react-intl"

const styles = theme => ({
    carrier__cell: {
        padding: "15px",
        display: "flex",
        justifyContent: "center",
    },
    carrier__cell__container: {
        backgroundColor: "#FFFFFF",
        "border-radius": "25px",
        width: "23%",
        margin: "1%",
    },
    carrier__title: {
        padding: "10px",
    },
    carrier__button: {
        margin: "0 20px",
    },
})

class CarrierCell extends Component {
    render() {
        const { classes, onCarrierSelect, carrier } = this.props

        return (
            <Paper className={classes.carrier__cell__container}>
                <Grid container className={classes.carrier__cell}>
                    <Grid
                        item
                        container
                        className={classes.carrier__image}
                        justify="center"
                        alignItems="center"
                    />
                    <Grid
                        item
                        container
                        className={classes.carrier__title}
                        justify="center"
                        alignItems="center"
                    >
                        <Typography color="primary" variant="subheading">
                            {get(carrier, "name")}
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        className={classes.carrier__button}
                        justify="center"
                        alignItems="center"
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            id={`select-carrier-button-${get(
                                carrier,
                                "capacityProviderIdentifiers[0].value",
                                ""
                            )}`}
                            onClick={() => onCarrierSelect(carrier)}
                        >
                            <FormattedMessage
                                id="addCarrier.table.select"
                                defaultMessage="Select"
                            />
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
        )
    }
}

CarrierCell.propTypes = {
    classes: PropTypes.object.isRequired,
    carrier: PropTypes.object.isRequired,
    onCarrierSelect: PropTypes.func.isRequired,
}

export default withStyles(styles)(CarrierCell)
