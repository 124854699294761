import React from "react"
import { Grid } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import CustomsBrokerageForm from "./CustomsBrokerageForm"

const CustomsBrokerage = ({
    isMXToUSIntl,
    classes,
    originCountry,
    destinationCountry,
    selectedLocation,
    secondSelectedLocation,
    changeField,
    isCanadaMexico,
    isIntl,
    isUSDomesticOffshore,
    category,
}) => {
    return (
        <Grid item container>
            {isMXToUSIntl ? (
                <Grid item container className={classes.sectionSmall}>
                    <CustomsBrokerageForm
                        isRequired={isMXToUSIntl}
                        country={originCountry}
                        formObjectName={"exportCustomsBrokerProfile"}
                        selectedLocation={selectedLocation}
                        changeField={changeField}
                        label={
                            <FormattedMessage
                                id="orderDetails.customs__export__title"
                                defaultMessage="Export Customs Brokerage Information"
                            />
                        }
                        gaCategory={`${category} : Brokerage Information`}
                    />
                </Grid>
            ) : null}
            {((isIntl && !isCanadaMexico) || isUSDomesticOffshore) && (
                <Grid item container className={classes.sectionSmall}>
                    <CustomsBrokerageForm
                        gaCategory={`${category} : Brokerage Information`}
                        isRequired={isIntl}
                        country={destinationCountry}
                        formObjectName={"importCustomsBrokerProfile"}
                        selectedLocation={selectedLocation}
                        changeField={changeField}
                        label={
                            <FormattedMessage
                                id="orderDetails.customs__import__title"
                                defaultMessage="Import Customs Brokerage Information"
                            />
                        }
                    />
                </Grid>
            )}
            {isCanadaMexico && destinationCountry === "MX" ? (
                <Grid container>
                    <Grid item container className={classes.sectionSmall}>
                        <CustomsBrokerageForm
                            isRequired={isCanadaMexico}
                            country={destinationCountry}
                            formObjectName={"importCustomsBrokerProfile"}
                            selectedLocation={selectedLocation}
                            secondSelectedLocation={secondSelectedLocation}
                            changeField={changeField}
                            label={
                                <FormattedMessage
                                    id="orderDetails.customs__USimport__title"
                                    defaultMessage="US Import Customs Brokerage Information"
                                />
                            }
                            gaCategory={`${category} : Brokerage Information`}
                        />
                    </Grid>
                    <Grid item container className={classes.sectionSmall}>
                        <CustomsBrokerageForm
                            isRequired={isCanadaMexico}
                            country={destinationCountry}
                            formObjectName={"exportCustomsBrokerProfile"}
                            selectedLocation={selectedLocation}
                            secondSelectedLocation={secondSelectedLocation}
                            changeField={changeField}
                            label={
                                <FormattedMessage
                                    id="orderDetails.customs__USexport__title"
                                    defaultMessage="Freight Forwarder/ US Export Broker/ US Customs Broker"
                                />
                            }
                            gaCategory={`${category} : Brokerage Information`}
                        />
                    </Grid>

                    <Grid item container className={classes.sectionSmall}>
                        <CustomsBrokerageForm
                            country={destinationCountry}
                            formObjectName={"camxCustomsBrokerProfile"}
                            selectedLocation={selectedLocation}
                            secondSelectedLocation={secondSelectedLocation}
                            changeField={changeField}
                            label={
                                <FormattedMessage
                                    id="orderDetails.customs__MXimport__title"
                                    defaultMessage="Mexico Import Customs Broker Information (Optional)"
                                />
                            }
                            gaCategory={`${category} : Brokerage Information`}
                        />
                    </Grid>
                </Grid>
            ) : null}

            {isCanadaMexico && destinationCountry === "CA" ? (
                <Grid item container>
                    <Grid item container className={classes.sectionSmall}>
                        <CustomsBrokerageForm
                            isRequired={isCanadaMexico}
                            country={destinationCountry}
                            formObjectName={"exportCustomsBrokerProfile"}
                            selectedLocation={selectedLocation}
                            secondSelectedLocation={secondSelectedLocation}
                            changeField={changeField}
                            label={
                                <FormattedMessage
                                    id="orderDetails.customs__MXexport__title"
                                    defaultMessage="Mexico Export Customs Brokerage Information"
                                />
                            }
                            gaCategory={`${category} : Brokerage Information`}
                        />
                    </Grid>
                    <Grid item container className={classes.sectionSmall}>
                        <CustomsBrokerageForm
                            isRequired={isCanadaMexico}
                            country={destinationCountry}
                            formObjectName={"importCustomsBrokerProfile"}
                            selectedLocation={selectedLocation}
                            secondSelectedLocation={secondSelectedLocation}
                            changeField={changeField}
                            label={
                                <FormattedMessage
                                    id="orderDetails.customs__USimport__title"
                                    defaultMessage="US Import Customs Brokerage Information"
                                />
                            }
                            gaCategory={`${category} : Brokerage Information`}
                        />
                    </Grid>
                    <Grid item container className={classes.sectionSmall}>
                        <CustomsBrokerageForm
                            isRequired={isCanadaMexico}
                            country={destinationCountry}
                            formObjectName={"camxCustomsBrokerProfile"}
                            selectedLocation={selectedLocation}
                            secondSelectedLocation={secondSelectedLocation}
                            changeField={changeField}
                            label={
                                <FormattedMessage
                                    id="orderDetails.customs__Canadaimport__title"
                                    defaultMessage="Canada Import Customs Brokerage Information"
                                />
                            }
                            gaCategory={`${category} : Brokerage Information`}
                        />
                    </Grid>
                </Grid>
            ) : null}
        </Grid>
    )
}

export default CustomsBrokerage
