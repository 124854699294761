import React, { useEffect, useState } from "react"
import { Element, scroller } from "react-scroll"
import ItemsSummary from "../summaryCards/ItemsSummary"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import { Field, FieldArray, reduxForm } from "redux-form"
import FormField from "../../../form/form-field"
import { FormattedMessage, injectIntl } from "react-intl"
import Typography from "@material-ui/core/Typography"
import SummaryButtons from "../atoms/SummaryButtons"
import NextButton from "../atoms/NextButton"
import { BookHandlingUnits } from "../atoms/BookHandlingUnits"
import { goFetch } from "../../../../http"
import { cloneDeep, set } from "lodash"
import {
    isInternationalShipment,
    isUSDomesticOffshoreShipmentWithItemCustoms,
    isUSDomesticShipment,
    hasTheShipmentDestinationOrOriginCountry,
} from "../../../../actions/validation"
import { supportedCurrencies } from "../../../../misc"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import { connect } from "react-redux"
import { searchItems } from "../../../../actions/item"
import { lengthUnit, weightUnit } from "../../../util/units"
import { Link, withStyles } from "@material-ui/core"
import { traverseSyncError } from "../atoms/getCurrentBSStep"
import SnackbarContent from "@material-ui/core/SnackbarContent"
import { amber } from "@material-ui/core/colors"
import { useFlags } from "launchdarkly-react-client-sdk"
import { openModal, closeModal } from "../../../../actions/modal"
import ElectronicExportInformation, {
    openEEIConfirmationModal,
} from "../atoms/ElectronicExportInformation"
import ConfirmActionModal from "../../../common/ConfirmActionModal"
import { bookShipmentFormValidation } from "../validators"

const styles = theme => ({
    section: {
        paddingTop: "10px",
    },
    warning: {
        backgroundColor: amber[700],
        maxWidth: "100%",
    },
    underline: {
        textDecoration: "underline",
    },
    bottomContainer: {
        paddingTop: "15px",
    },
    freightClass__helper: {
        textDecoration: "underline",
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.secondary.main,
            cursor: "pointer",
        },
    },
})

const GA_CATEGORY = "Book Shipment - Items"

const Items = ({
    handleComplete,
    handleEdit,
    handleCancelEdit,
    currentStep,
    editMode,
    formValues = { handlingUnits: [] },
    handleHUPanel,
    isHUOpen,
    changeField,
    getRates,
    addNewHandlingUnit,
    loadItems,
    itemsIsLoaded,
    favoriteItems,
    doSearch,
    invalid,
    trackGA,
    isLoading,
    classes,
    handlingUnitSyncErrors,
    IntercomAPI,
    isCanadaMexico,
    isCanadaAlaskaShipment,
    originCountry,
    destinationCountry,
    setShowCapLoadWarning,
    openModal,
    closeModal,
    checkLoadedHISpecialCases,
}) => {
    const {
        isFreightDirect,
        isFreightDirectReturns,
        isFreightBox,
        preferredSystemOfMeasurement,
        handlingUnits = [],
        origin,
        mode,
        destination,
        selectedLocation = {},
    } = formValues

    const isIntl = isInternationalShipment(originCountry, destinationCountry)

    const {
        lineItemsRoundingErrorMessage,
        eeiCheckbox,
        domesticOffshoreHiRating,
        multiCarrierSpacePaceWorkflow,
    } = useFlags()

    const [shipmentWeight, setShipmentWeight] = useState(0)
    const [enteredWeight, setEnteredWeight] = useState(0)

    const isMXRate = hasTheShipmentDestinationOrOriginCountry(
        originCountry,
        destinationCountry,
        "MX"
    )

    const isUSDomesticOffshoreWithItemCustoms = isUSDomesticOffshoreShipmentWithItemCustoms(
        originCountry,
        destinationCountry,
        origin?.shippingAddress?.address?.state,
        destination?.shippingAddress?.address?.state
    )

    const isIntraMexico = originCountry === "MX" && destinationCountry === "MX"

    const isUSDomestic = isUSDomesticShipment(originCountry, destinationCountry)

    const isUsDimWeightEnabled = selectedLocation?.usDimWeight ?? false

    const destinationState = destination?.shippingAddress?.address?.state
    const originState = origin?.shippingAddress?.address?.state

    const isPuertoRicoShipment =
        isUSDomestic &&
        originState !== destinationState &&
        (originState === "PR" || destinationState === "PR")

    const isCanadaPRShipment =
        ((originCountry === "CA" && destinationCountry === "US") ||
            (originCountry === "US" && destinationCountry === "CA")) &&
        (destinationState === "PR" || originState === "PR")

    const shouldShowITNInformationModal = () => {
        return (
            showEEI() &&
            !formValues.isFedexFileEEI &&
            formValues.itns &&
            !formValues.itns[0]
        )
    }

    useEffect(() => {
        if (currentStep === 3) {
            if (isIntl) {
                IntercomAPI(
                    "trackEvent",
                    "book-shipment-line-items-international"
                )
            } else {
                IntercomAPI("trackEvent", "book-shipment-line-items")
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIntl])

    useEffect(() => {
        if (currentStep === 3) {
            loadItems()
            scroller.scrollTo("itemsTitle", {
                duration: 1000,
                smooth: true,
                offset: -140,
            })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editMode])

    useEffect(() => {
        setShipmentWeight(getShipmentWeight())
        setEnteredWeight(getEnteredWeight())
        // if (currentStep === 3) {
        //     setShipmentWeight(getShipmentWeight())
        //     setEnteredWeight(getEnteredWeight())
        // }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handlingUnits, editMode])

    useEffect(() => {
        if (isIntraMexico || (isCanadaMexico && originCountry === "MX")) {
            adjustEstimatedVolumenCubicMeter()
        } else {
            adjustEstimatedLinearFeet()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [handlingUnits.length])

    useEffect(() => {
        if (!showEEI()) {
            changeField("itns", [])
            changeField("isFedexFileEEI", null)
        }
    }, [
        formValues?.handlingUnits?.some(hu =>
            hu.items?.some(item => item.unitPrice > 2500)
        ),
    ])

    const adjustEstimatedVolumenCubicMeter = (prefix, fieldName, value) => {
        let adjustedFormValues = cloneDeep(formValues)

        set(adjustedFormValues, `${prefix}.${fieldName}`, value)

        const relevantFieldsFilled = (
            adjustedFormValues?.handlingUnits ?? []
        ).every(item => item.length && item.width && item.height && item.count)

        if (relevantFieldsFilled && handlingUnits.length > 0) {
            calculateEstimatedCubicVolumenMeter(
                adjustedFormValues.handlingUnits
            )
        }
    }

    const adjustEstimatedCubicVolumenMeterOnSearchChange = (
        prefix,
        length,
        width,
        height,
        count,
        doNotStack
    ) => {
        let adjustedFormValues = cloneDeep(formValues)

        set(adjustedFormValues, `${prefix}.length`, length)
        set(adjustedFormValues, `${prefix}.width`, width)
        set(adjustedFormValues, `${prefix}.height`, height)
        set(adjustedFormValues, `${prefix}.count`, count)
        set(adjustedFormValues, `${prefix}.doNotStack`, doNotStack)

        const relevantFieldsFilled = adjustedFormValues?.handlingUnits.every(
            item => item.length && item.width && item.height && item.count
        )

        if (relevantFieldsFilled && handlingUnits.length > 0) {
            calculateEstimatedCubicVolumenMeter(
                adjustedFormValues.handlingUnits
            )
        }
    }

    const adjustEstimatedLinearFeet = (prefix, fieldName, value) => {
        let adjustedFormValues = cloneDeep(formValues)

        set(adjustedFormValues, `${prefix}.${fieldName}`, value)
        if (fieldName === "doNotStack") {
            set(adjustedFormValues, `${prefix}.stackable`, !value)
        }

        const relevantFieldsFilled = (
            adjustedFormValues?.handlingUnits ?? []
        ).every(item => item.length && item.width && item.height && item.count)

        if (relevantFieldsFilled && handlingUnits.length > 0) {
            calculateEstimatedLinearFeet(adjustedFormValues.handlingUnits)
        }
    }

    const adjustEstimatedLinearFeetOnSearchChange = (
        prefix,
        length,
        width,
        height,
        count,
        doNotStack
    ) => {
        let adjustedFormValues = cloneDeep(formValues)

        set(adjustedFormValues, `${prefix}.length`, length)
        set(adjustedFormValues, `${prefix}.width`, width)
        set(adjustedFormValues, `${prefix}.height`, height)
        set(adjustedFormValues, `${prefix}.count`, count)
        set(adjustedFormValues, `${prefix}.doNotStack`, doNotStack)

        const relevantFieldsFilled = adjustedFormValues?.handlingUnits.every(
            item => item.length && item.width && item.height && item.count
        )

        if (relevantFieldsFilled && handlingUnits.length > 0) {
            calculateEstimatedLinearFeet(adjustedFormValues.handlingUnits)
        }
    }

    const calculateEstimatedLinearFeet = async handlingUnits => {
        const { preferredSystemOfMeasurement, origin, destination } = formValues

        try {
            const {
                data: {
                    totalLinearFeet,
                    capLoadTotalLinearFeet,
                    volumeTotalLinearFeet,
                    isOverLTLLimit,
                    showWarning,
                },
            } = await goFetch(
                `/quotes/linearfeet`,
                {
                    method: "POST",
                    credentials: "same-origin",
                    headers: {
                        "cache-control": "no-cache",
                    },
                    data: {
                        customCapacityLoadLimit:
                            formValues?.selectedLocation?.shipmentRestrictions
                                ?.capacityShipmentLength,
                        handlingUnits,
                        preferredSystemOfMeasurement,
                        originCountry:
                            origin?.shippingAddress?.address?.country,
                        destinationCountry:
                            destination?.shippingAddress?.address?.country,
                    },
                },
                true
            )
            changeField("totalLinearFeet", totalLinearFeet)
            changeField("capLoadTotalLinearFeet", capLoadTotalLinearFeet)
            changeField("volumeTotalLinearFeet", volumeTotalLinearFeet)
            changeField("userProvidedTotalLinearFeet", false)
            changeField("isOverLTLLimit", isOverLTLLimit)
            setShowCapLoadWarning(showWarning)
        } catch (error) {
            console.error(error)
        }
    }

    const calculateEstimatedCubicVolumenMeter = handlingUnits => {
        const { preferredSystemOfMeasurement } = formValues
        let handlingUnitsCloned = cloneDeep(handlingUnits)
        const inchToMtMultiplier = 0.0254
        const cmToMtdivider = 100
        let totalCubicVolumenMeter = 0
        try {
            handlingUnitsCloned.forEach(item => {
                if (preferredSystemOfMeasurement === "IMPERIAL") {
                    item.length *= inchToMtMultiplier
                    item.width *= inchToMtMultiplier
                    item.height *= inchToMtMultiplier
                } else {
                    item.length /= cmToMtdivider
                    item.width /= cmToMtdivider
                    item.height /= cmToMtdivider
                }

                totalCubicVolumenMeter +=
                    item.length * item.width * item.height * item.count
            })
            changeField(
                "totalCubicVolumenMeter",
                totalCubicVolumenMeter.toFixed(10)
            )
        } catch (error) {
            console.error(error)
        }
    }

    const getEnteredWeight = () => {
        return formValues?.handlingUnits?.reduce((acc, hu) => {
            return acc + Number(hu.totalWeight)
        }, 0)
    }

    const getShipmentWeight = () => {
        const weight =
            formValues?.handlingUnits?.reduce((acc, hu) => {
                if (hu.isMultiClass) {
                    const result = hu?.items.reduce((acc, item) => {
                        if (item.weight) {
                            return (acc += Number(item?.weight))
                        } else return acc
                    }, 0)

                    const finalResult = result * hu.count
                    return acc + finalResult
                } else {
                    const finalResult = parseInt(hu?.items[0].weight ?? 0, 10)
                    return acc + (finalResult ? finalResult * hu?.count : 0)
                }
            }, 0) ?? 0

        return weight
    }

    const showEEI = () => {
        return (
            eeiCheckbox &&
            isPuertoRicoShipment &&
            formValues.handlingUnits.some(hu =>
                hu.items.some(item => item.unitPrice > 2500)
            )
        )
    }

    const determineInvalidHandlingUnit = (
        handlingUnitSyncErrors,
        traverseSyncError
    ) => {
        if (handlingUnitSyncErrors) {
            for (let hu in handlingUnitSyncErrors) {
                if (traverseSyncError(handlingUnitSyncErrors[hu])) {
                    return Number(hu)
                }
            }
        }
    }

    const isHawaiiShipment =
        origin?.shippingAddress?.address?.state === "HI" ||
        destination?.shippingAddress?.address?.state === "HI"

    const isOriginHISpecialCase = [
        checkLoadedHISpecialCases(origin?.shippingAddress?.address),
    ].some(
        specialCase =>
            specialCase?.specialHandling === "Addtl Charges may Apply"
    )

    const isDestinationHISpecialCase = [
        checkLoadedHISpecialCases(destination?.shippingAddress?.address),
    ].some(
        specialCase =>
            specialCase?.specialHandling === "Addtl Charges may Apply"
    )

    const isHISpecialCase = isOriginHISpecialCase || isDestinationHISpecialCase

    const openAdditionalChargesConfirmationModal = (
        openModal,
        handleConfirm
    ) => {
        openModal(
            <ConfirmActionModal
                classes
                handleConfirm={handleConfirm}
                message={
                    <FormattedMessage
                        id={"specialHandling_additionalCharges_msg"}
                        defaultMessage="Your shipment is in an zone that may require Additional Charges. For more information, call 1-800-351-5187 Ext. 6."
                    ></FormattedMessage>
                }
                title={
                    <FormattedMessage
                        id="specialHandling_additionalCharges_title"
                        defaultMessage="Additional Charges May Apply"
                    />
                }
            ></ConfirmActionModal>
        )
    }

    useEffect(() => {
        if (isIntraMexico) {
            changeField("preferredCurrencyCode", "MXN")
            changeField("preferredSystemOfMeasurement", "METRIC")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isIntraMexico])

    if (currentStep === 3) {
        return (
            <Grid item container>
                <Grid item container>
                    <Grid item container xs={4} />
                    <Grid item container xs={4} justifyContent="center">
                        <Typography variant="h6">
                            <FormattedMessage
                                id="bookShipment.items__title"
                                defaultMessage="Line Items"
                            />
                        </Typography>
                        <Element name="itemsTitle" />
                    </Grid>
                    {(multiCarrierSpacePaceWorkflow
                        ? true
                        : !isUsDimWeightEnabled) && (
                        <Grid
                            item
                            container
                            xs={4}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <Link
                                onClick={() =>
                                    trackGA(
                                        GA_CATEGORY,
                                        "Clicked What's my freight class"
                                    )
                                }
                                underline="hover"
                                rel="noopener noreferrer"
                                href="https://fedex.postclickmarketing.com/LTL-freight-classification-tool"
                                target="_blank"
                            >
                                <Typography
                                    variant="caption"
                                    className={classes.freightClass__helper}
                                >
                                    <FormattedMessage
                                        id="items__freightClassLink"
                                        defaultMessage="What's my freight class?"
                                    />
                                </Typography>
                            </Link>
                        </Grid>
                    )}
                </Grid>
                {isIntl &&
                    hasTheShipmentDestinationOrOriginCountry(
                        originCountry,
                        destinationCountry,
                        "US"
                    ) && (
                        <Grid item container>
                            <Grid item container xs={4} />
                            <Grid item container xs={4} />
                            <Grid
                                item
                                container
                                xs={4}
                                justifyContent="flex-end"
                                alignItems="flex-end"
                            >
                                <a
                                    rel="noopener noreferrer"
                                    href="https://hts.usitc.gov/"
                                    target="_blank"
                                >
                                    <Typography
                                        variant="caption"
                                        className={classes.freightClass__helper}
                                    >
                                        <FormattedMessage
                                            id="items__hscLink__us"
                                            defaultMessage="US - What's my HSC?"
                                        />
                                    </Typography>
                                </a>
                            </Grid>
                        </Grid>
                    )}
                {isIntl &&
                    hasTheShipmentDestinationOrOriginCountry(
                        originCountry,
                        destinationCountry,
                        "CA"
                    ) && (
                        <Grid item container>
                            <Grid item container xs={4} />
                            <Grid item container xs={4} />
                            <Grid
                                item
                                container
                                xs={4}
                                justifyContent="flex-end"
                                alignItems="flex-end"
                            >
                                <a
                                    rel="noopener noreferrer"
                                    href="https://www.tariffinder.ca/en/getStarted"
                                    target="_blank"
                                >
                                    <Typography
                                        variant="caption"
                                        className={classes.freightClass__helper}
                                    >
                                        <FormattedMessage
                                            id="items__hscLink__ca"
                                            defaultMessage="CA - What's my HSC?"
                                        />
                                    </Typography>
                                </a>
                            </Grid>
                        </Grid>
                    )}
                {!isUSDomestic && !isFreightBox && (
                    <Grid item container className={classes.section}>
                        <Grid item container xs={6}>
                            <Field
                                component={FormSelectAutocomplete}
                                name="preferredCurrencyCode"
                                label={
                                    <FormattedMessage
                                        id="bookShipment.preferredCurrency"
                                        defaultMessage="Preferred Currency"
                                    />
                                }
                                options={supportedCurrencies}
                                disabled={
                                    isCanadaMexico ||
                                    isCanadaPRShipment ||
                                    isCanadaAlaskaShipment ||
                                    isHawaiiShipment
                                }
                            />
                        </Grid>
                        <Grid item container xs={6}>
                            <Field
                                component={FormSelectAutocomplete}
                                name="preferredSystemOfMeasurement"
                                label={
                                    <FormattedMessage
                                        id="bookShipment.preferredSystemOfMeasurement"
                                        defaultMessage="Preferred System of Measurement"
                                    />
                                }
                                options={[
                                    {
                                        value: "IMPERIAL",
                                        label: "lbs/in",
                                    },
                                    {
                                        value: "METRIC",
                                        label: "kg/cm",
                                    },
                                ]}
                                disabled={isCanadaPRShipment}
                            />
                        </Grid>
                    </Grid>
                )}
                <Grid item container className={classes.section}>
                    <FieldArray
                        name="handlingUnits"
                        component={BookHandlingUnits}
                        handlePanel={handleHUPanel}
                        isOpen={isHUOpen}
                        isFreightDirect={isFreightDirect}
                        isFreightDirectReturns={isFreightDirectReturns}
                        isFreightBox={isFreightBox}
                        formValues={formValues}
                        changeField={changeField}
                        weightUnit={weightUnit(preferredSystemOfMeasurement)}
                        lengthUnit={lengthUnit(preferredSystemOfMeasurement)}
                        adjustEstimatedLinearFeet={adjustEstimatedLinearFeet}
                        adjustEstimatedLinearFeetOnSearchChange={
                            adjustEstimatedLinearFeetOnSearchChange
                        }
                        adjustEstimatedVolumenCubicMeter={
                            adjustEstimatedVolumenCubicMeter
                        }
                        adjustEstimatedCubicVolumenMeterOnSearchChange={
                            adjustEstimatedCubicVolumenMeterOnSearchChange
                        }
                        isIntl={isIntl}
                        isUSDomesticOffshoreWithItemCustoms={
                            isUSDomesticOffshoreWithItemCustoms
                        }
                        isIntraMexico={isIntraMexico}
                        favoriteItems={favoriteItems}
                        itemsIsLoaded={itemsIsLoaded}
                        doSearch={doSearch}
                        trackGA={trackGA}
                        isCanadaMexico={isCanadaMexico}
                        isMXRate={isMXRate}
                        editMode={editMode}
                        originCountry={originCountry}
                        isUsDimWeightEnabled={isUsDimWeightEnabled}
                    />
                </Grid>
                <Grid
                    item
                    container
                    className={classes.bottomContainer}
                    alignItems="center"
                >
                    <Grid item container xs={4}>
                        <FieldArray
                            name="handlingUnits"
                            component={({ fields }) => (
                                <Button
                                    id={"addLineItem"}
                                    className={classes.button__add_item}
                                    color="primary"
                                    type="button"
                                    onClick={() => {
                                        addNewHandlingUnit(fields)
                                    }}
                                >
                                    +{" "}
                                    <FormattedMessage
                                        id="bookShipment.addLineItem"
                                        defaultMessage="Add Line Item"
                                    />
                                </Button>
                            )}
                        />
                    </Grid>
                    <Grid
                        item
                        container
                        xs={4}
                        justify="center"
                        alignItems="center"
                    >
                        <Typography
                            id="shipmentWeight"
                            align="center"
                            variant="subtitle1"
                        >
                            <FormattedMessage
                                id="getRates.totalShipmentWeight"
                                defaultMessage="TOTAL SHIPMENT WEIGHT: {shipmentWeight} {weightUnit}"
                                values={{
                                    shipmentWeight,
                                    weightUnit: weightUnit(
                                        preferredSystemOfMeasurement
                                    ),
                                }}
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={2}></Grid>
                    <Grid item container xs={2} justify="flex-end">
                        {isIntraMexico ||
                        (isCanadaMexico && originCountry === "MX") ? (
                            <Field
                                component={FormField}
                                category={GA_CATEGORY}
                                name="totalCubicVolumenMeter"
                                id="getRates__totalLinearFeet"
                                label={
                                    <FormattedMessage
                                        id="getRates.cubicMeter"
                                        defaultMessage="Est. Cubic Meters"
                                    />
                                }
                                readonly={true}
                            />
                        ) : (
                            <Field
                                component={FormField}
                                category={GA_CATEGORY}
                                name="volumeTotalLinearFeet"
                                label={
                                    <FormattedMessage
                                        id="getRates.estLinearFeet"
                                        defaultMessage="Est. Linear Feet"
                                    />
                                }
                                required
                                onChange={() => {
                                    trackGA(
                                        GA_CATEGORY,
                                        "User Provided Total Linear Feet"
                                    )
                                    changeField(
                                        "userProvidedTotalLinearFeet",
                                        true
                                    )
                                }}
                            />
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        xs={12}
                        justify="center"
                        alignItems="center"
                    >
                        {lineItemsRoundingErrorMessage &&
                            !!shipmentWeight &&
                            enteredWeight !== shipmentWeight && (
                                <SnackbarContent
                                    className={classes.warning}
                                    message={
                                        <Typography variant="body2">
                                            <FormattedMessage
                                                id="getRates.HU__weight__adjusted"
                                                defaultMessage="Individual handling unit weights are rounded up to the nearest whole number"
                                            />
                                        </Typography>
                                    }
                                />
                            )}
                    </Grid>
                </Grid>

                {showEEI() ? (
                    <ElectronicExportInformation
                        changeField={changeField}
                        formValues={formValues}
                        classes={classes}
                    ></ElectronicExportInformation>
                ) : null}

                {editMode ? (
                    <SummaryButtons
                        handleCancelEdit={handleCancelEdit}
                        handleUpdate={() => {
                            if (shouldShowITNInformationModal()) {
                                openEEIConfirmationModal(openModal, () => {
                                    closeModal()
                                    getRates()
                                })
                            } else if (
                                isHawaiiShipment &&
                                domesticOffshoreHiRating &&
                                isHISpecialCase
                            ) {
                                openAdditionalChargesConfirmationModal(
                                    openModal,
                                    () => {
                                        closeModal()
                                        getRates()
                                    }
                                )
                            } else {
                                getRates()
                            }
                        }}
                        invalid={invalid || handlingUnits.length === 0}
                    />
                ) : (
                    <NextButton
                        handleComplete={isInvalidGetRatesButton => {
                            if (isInvalidGetRatesButton) {
                                handleComplete(isInvalidGetRatesButton)
                                const firstInvalidHU = determineInvalidHandlingUnit(
                                    handlingUnitSyncErrors,
                                    traverseSyncError
                                )
                                if (firstInvalidHU !== isHUOpen) {
                                    handleHUPanel(firstInvalidHU)
                                }
                            } else if (shouldShowITNInformationModal()) {
                                openEEIConfirmationModal(openModal, () => {
                                    closeModal()
                                    getRates()
                                })
                            } else if (
                                isHawaiiShipment &&
                                domesticOffshoreHiRating &&
                                isHISpecialCase
                            ) {
                                openAdditionalChargesConfirmationModal(
                                    openModal,
                                    () => {
                                        closeModal()
                                        getRates()
                                    }
                                )
                            } else {
                                getRates()
                            }
                        }}
                        isItemsStep
                        invalid={invalid || handlingUnits.length === 0}
                        isLoading={isLoading}
                    />
                )}
            </Grid>
        )
    } else if (currentStep > 3) {
        return (
            <ItemsSummary
                handleEdit={handleEdit}
                formValues={formValues}
                weightUnit={weightUnit(preferredSystemOfMeasurement)}
            />
        )
    }
}

const mapDispatchToProps = dispatch => ({
    doSearch: (value, origin, originAddress) =>
        dispatch(searchItems(value, origin, originAddress)),
    openModal: async node => dispatch(openModal(node)),
    closeModal: () => {
        dispatch(closeModal())
    },
})

const mapStateToProps = state => {
    return {
        itemsIsLoaded: state.item.isLoaded,
        favoriteItems: (state.item.elements ?? []).slice(1, 51),
    }
}

export default injectIntl(
    withStyles(styles)(
        connect(
            mapStateToProps,
            mapDispatchToProps
        )(
            reduxForm({
                // a unique name for the form
                form: "bookShipment",
                validate: bookShipmentFormValidation,
                destroyOnUnmount: false,
                forceUnregisterOnUnmount: true,
            })(Items)
        )
    )
)
