import React, { useState, useEffect } from "react"
import Grid from "@material-ui/core/Grid"
import Button from "@material-ui/core/Button"
import Typography from "@material-ui/core/Typography"
import GlobalSpinner from "../../../common/GlobalSpinner"
import { withStyles } from "@material-ui/core/styles"
import CloseIcon from "@material-ui/icons/Close"
import IconButton from "@material-ui/core/IconButton"
import { FormattedMessage } from "react-intl"
import { loadBoLImage, loadShippingLabels } from "../../../../actions/modal"
import { connect } from "react-redux"
import { goFetch } from "../../../../http"
import { attachmentOptions } from "../../../../misc"
import { useGAContext } from "../../../../context/providers/GoogleAnalyticsProvider"

const nonDeletableDocuments = Object.freeze([
    "commercialInvoice",
    "certificateOfOrigin",
    "trailerManifest",
])

const styles = theme => ({
    paper: {
        position: "absolute",
        transform: "translate(-50%, -50%)",
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        top: "50%",
        left: "50%",
        width: "900px",
        maxHeight: "700px",
    },
    actions: {
        padding: "15px 0 0 0",
    },
    actions__button: {
        paddingLeft: "15px",
    },
    header: {
        paddingBottom: "15px",
        borderBottom: "solid 1px #D4D4D4",
    },
    previewImage: {
        width: "100%",
    },
    previewImage__container: {
        height: "470px",
        overflowY: "scroll",
        borderLeft: "solid 1px #D4D4D4",
        padding: "5px",
    },
    details__container: {
        padding: "20px 10px 20px 0",
    },
    extraInfo: {
        paddingTop: "20px",
    },
})

const ManageAttachmentDialogPresentation = ({
    classes,
    onClose,
    file = {},
    downloadFile,
    deleteFile,
    shipmentId,
    loadBolImageData,
    loadLabelsData,
    loading,
    printFile,
    openDialog,
    carrier,
    isFromTrailerManifestPage,
    activeManifestFile,
    trailerManifestLoading,
    gaCategory,
}) => {
    const {
        isSystem,
        isSigned,
        attachmentFileName,
        attachmentFileType,
        userFileName,
        filePath,
    } = file
    const [isLoading, setIsLoading] = useState(false)
    const [data, setData] = useState({})
    const [isError, setIsError] = useState(false)
    const [useDataUrl, setUseDataUrl] = useState(false)
    const { logGAEvent } = useGAContext()

    useEffect(() => {
        if (isFromTrailerManifestPage) {
            return
        }
        if (attachmentFileType === "bol") {
            const fetchData = async () => {
                setIsError(false)
                setIsLoading(true)

                try {
                    const result = await loadBolImageData(shipmentId, filePath)

                    setData(result)
                } catch (error) {
                    setIsError(true)
                }

                setIsLoading(false)
                setUseDataUrl(true)
            }

            fetchData()
        } else if (attachmentFileType === "labels") {
            const fetchData = async () => {
                setIsError(false)
                setIsLoading(true)

                try {
                    const result = await loadLabelsData(shipmentId, filePath)

                    setData(result)
                } catch (error) {
                    setIsError(true)
                }

                setIsLoading(false)
                setUseDataUrl(true)
            }

            fetchData()
        } else {
            const fetchData = async () => {
                setIsError(false)
                setIsLoading(true)

                const paramsList = {
                    type: attachmentFileType,
                    format: "png",
                }
                if (attachmentFileType !== "trailerManifest") {
                    paramsList.filename = attachmentFileName
                }

                try {
                    const { data } = await goFetch(
                        `/documents/${shipmentId}`,
                        {
                            params: paramsList,
                            method: "GET",
                            responseType: "blob",
                            headers: { Accept: "image/png" },
                        },
                        true
                    )
                    const result = await new Promise((resolve, reject) => {
                        const reader = new FileReader()
                        reader.readAsDataURL(data)
                        reader.onloadend = () => resolve(reader.result)
                        reader.onerror = reject
                    })
                    setData(result)
                } catch (error) {
                    setIsError(true)
                }
                setIsLoading(false)
                setUseDataUrl(true)
            }

            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const categoryLabel = attachmentOptions.find(
        entry => attachmentFileType === entry.value
    )
    const label = categoryLabel ? (
        <FormattedMessage {...categoryLabel.label} />
    ) : (
        file?.label
    )

    const imgSrc = isFromTrailerManifestPage
        ? activeManifestFile
        : useDataUrl
        ? data
        : attachmentFileName

    return (
        <Grid item container className={classes.paper}>
            <Grid item container className={classes.header}>
                <Grid
                    item
                    container
                    justify="space-between"
                    alignItems="center"
                >
                    <Typography variant="h4">
                        <FormattedMessage
                            id="documents.manage__title"
                            defaultMessage="Manage Document"
                        />
                    </Typography>
                    <IconButton onClick={() => onClose("manage")}>
                        <CloseIcon />
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item container xs={4} className={classes.details__container}>
                <Grid item container>
                    <Grid item container direction="column">
                        <Grid item>
                            <Typography variant="h6">{label}</Typography>
                        </Grid>
                        {userFileName && (
                            <Grid item>
                                <Typography variant="subtitle1">
                                    {userFileName}
                                </Typography>
                            </Grid>
                        )}
                        <Grid item>
                            <Typography variant="caption">
                                {file.isSystem ? (
                                    <FormattedMessage
                                        id="documents.manage__systemFile"
                                        defaultMessage="System File"
                                    />
                                ) : (
                                    file?.originalFileName
                                )}
                            </Typography>
                        </Grid>
                        {(attachmentFileType === "bol" ||
                            attachmentFileType === "labels") &&
                        (carrier === "FXFE" || carrier === "FXNL") ? (
                            <Grid item className={classes.extraInfo}>
                                <Typography variant="body2">
                                    <FormattedMessage
                                        id="documents.labelsInfo"
                                        defaultMessage="Domestic shipping does not require a BOL when using the FedEx shipping label (except for shipments containing hazardous materials)."
                                    />
                                </Typography>
                            </Grid>
                        ) : null}
                        {(attachmentFileType === "systemCertificateOfOrigin" ||
                            attachmentFileType === "systemCommercialInvoice") &&
                        !isSigned &&
                        (carrier === "FXFE" || carrier === "FXNL") ? (
                            <Grid item className={classes.extraInfo}>
                                <Typography variant="body2">
                                    <FormattedMessage
                                        id="documents.tradeDocumentsInfo"
                                        defaultMessage="LTL Select has generated unsigned trade documents for this shipment. FedEx requires trade documentation to be signed. Please print or download your trade documents, sign them, and re-upload the signed versions which will then be sent to FedEx."
                                    />
                                </Typography>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>
            <Grid
                item
                container
                xs={8}
                justify="center"
                className={classes.previewImage__container}
            >
                {!isLoading && imgSrc && !isError ? (
                    <Grid item>
                        <img
                            className={classes.previewImage}
                            src={imgSrc}
                            alt={
                                <FormattedMessage
                                    id="documents__unavailable"
                                    defaultMessage="Unavailable"
                                />
                            }
                        />
                    </Grid>
                ) : null}
                {isError ||
                (isFromTrailerManifestPage &&
                    imgSrc === null &&
                    !trailerManifestLoading) ? (
                    <FormattedMessage
                        id="documents__unavailable"
                        defaultMessage="Unavailable"
                    />
                ) : null}
                {loading ? <GlobalSpinner /> : null}
                {isLoading || trailerManifestLoading ? (
                    <GlobalSpinner isGlobal={false} />
                ) : null}
            </Grid>
            <Grid item container className={classes.actions}>
                <Grid item container>
                    <Grid item container xs={3}>
                        {!isSystem &&
                        !nonDeletableDocuments.includes(attachmentFileType) ? (
                            <Button
                                color="secondary"
                                variant="contained"
                                onClick={() => deleteFile(file)}
                            >
                                <FormattedMessage
                                    id="documents.manage__delete"
                                    defaultMessage="Delete"
                                />
                            </Button>
                        ) : null}
                    </Grid>
                    <Grid item container xs={9} justify="flex-end">
                        <Grid item className={classes.actions__button}>
                            <Button
                                color="primary"
                                variant="outlined"
                                onClick={() => {
                                    logGAEvent(gaCategory, "Share button Click")
                                    openDialog(
                                        shipmentId,
                                        "share",
                                        file,
                                        undefined,
                                        undefined,
                                        gaCategory
                                    )
                                }}
                            >
                                <FormattedMessage
                                    id="documents.manage__share"
                                    defaultMessage="Share"
                                />
                            </Button>
                        </Grid>
                        {!file.isSystem || isFromTrailerManifestPage ? (
                            <Grid item className={classes.actions__button}>
                                <Button
                                    color="primary"
                                    variant={
                                        attachmentFileType === "trailerManifest"
                                            ? "outlined"
                                            : "contained"
                                    }
                                    onClick={() => downloadFile(file)}
                                >
                                    <FormattedMessage
                                        id="documents.manage__download"
                                        defaultMessage="Download"
                                    />
                                </Button>
                            </Grid>
                        ) : null}
                        {isSystem ? (
                            <Grid item className={classes.actions__button}>
                                <Button
                                    color="primary"
                                    variant="contained"
                                    onClick={() => printFile(file)}
                                >
                                    <FormattedMessage
                                        id="documents.manage__print"
                                        defaultMessage="Print"
                                    />
                                </Button>
                            </Grid>
                        ) : null}
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    )
}

const mapStateToProps = (state, props) => {
    return {
        carrier: state?.shipment?.list[props.shipmentId]?.shipment.carrier,
        activeManifestFile: state?.trailerManifest?.activeManifestFile,
        trailerManifestLoading: state?.trailerManifest?.trailerManifestLoading,
    }
}

const mapDispatchToProps = dispatch => ({
    loadBolImageData: (shipmentId, filePath) =>
        dispatch(loadBoLImage(shipmentId, filePath)),
    loadLabelsData: (shipmentId, filePath) =>
        dispatch(loadShippingLabels(shipmentId, filePath)),
})

export const ManageAttachmentDialog = connect(
    mapStateToProps,
    mapDispatchToProps
)(withStyles(styles)(ManageAttachmentDialogPresentation))
