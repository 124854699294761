import React, { useEffect, useState } from "react"
import { Button, Grid, Typography, withStyles } from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { Field } from "redux-form"
import FormSelectBillTo from "../../../form/form-select-bill-to"
import { connect } from "react-redux"
import ArrowForwardIcon from "@material-ui/icons/ArrowForward"
import { useFlags } from "launchdarkly-react-client-sdk"

const styles = theme => ({
    paper: {
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: theme.palette.background.paper,
        boxShadow: theme.shadows[5],
        padding: theme.spacing(3),
        width: "900px",
        minHeight: "300px",
        height: "fit-content",
        maxHeight: "700px",
        marginTop: "auto",
        marginLeft: "auto",
        marginRight: "auto",
        marginBottom: "auto",
    },

    mainContainer: {
        minHeight: "300px",
    },

    section: {
        paddingTop: "25px",
    },

    buttonContainer: {
        flexGrow: 1,
    },
    smallSection: {
        paddingTop: "10px",
    },
    underLineText: {
        textDecoration: "underline",
    },
    forwardIcon: {
        color: "black",
    },
})

const SecondLocationModal = ({
    classes,
    formattedLocations,
    beforeNextStep,
    secondBillToLocation,
    selectedLocation,
    changeField,
    setLocationModalOpen,
    locations = [],
    mode,
    origin,
    destination,
    secondSelectedLocation,
    displayAddressMismatchDetails = true,
}) => {
    const { canadaMexicoLocationModal } = useFlags()

    const [modalOptions, setModalOptions] = useState([])
    const [isInvalidLocationEntry, setIsInvalidLocationEntry] = useState(false)
    const [
        invalidLocationEntryOrigin,
        setInvalidLocationEntryOrigin,
    ] = useState([])

    useEffect(() => {
        const filteredLocationOptions = createModalDropdownOptions(
            selectedLocation,
            formattedLocations
        )

        if (filteredLocationOptions.length === 1) {
            changeField(
                "billToLocationSecondAccount",
                filteredLocationOptions[0]
            )
        }

        setModalOptions(filteredLocationOptions)
    }, [])

    useEffect(() => {
        if (
            selectedLocation?.address?.postalCode === String(78045) &&
            mode === "inbound"
        ) {
            setInvalidLocationEntryOrigin([
                "destination",
                "destination.shippingAddress",
                destination.shippingAddress,
            ])
        } else if (mode === "inbound") {
            setInvalidLocationEntryOrigin([
                "origin",
                "origin.shippingAddress",
                origin.shippingAddress,
            ])
        } else {
            setInvalidLocationEntryOrigin([
                "destination",
                "destination.shippingAddress",
                destination.shippingAddress,
            ])
        }
    }, [mode, origin.shippingAddress, destination.shippingAddress])

    const createModalDropdownOptions = (
        selectedLocation,
        formattedLocations
    ) => {
        const {
            fedexFreightAccountType = "",
            fedexBillToAccountType = "",
        } = selectedLocation

        const isFXFC =
            fedexFreightAccountType === "FXFC" ||
            fedexBillToAccountType === "FXFC"
        const isFXFM =
            fedexFreightAccountType === "FXFM" ||
            fedexBillToAccountType === "FXFM"
        const isFXFE_ThirdParty = fedexBillToAccountType === "FXFE"
        const isFXFE_Shipper = fedexFreightAccountType === "FXFE"

        const IsAliasInboundAccount =
            selectedLocation?.address?.postalCode === String(78045) &&
            mode === "inbound"

        const isCanadaToMexico =
            origin.shippingAddress.address.country === "CA" &&
            destination.shippingAddress.address.country === "MX"

        const isMexicoToCanada =
            origin.shippingAddress.address.country === "MX" &&
            destination.shippingAddress.address.country === "CA"

        // Conditional check based on `canadaMexicoLocationModal` status
        const freightFilterCondition = location => {
            if (isFXFC) {
                return (
                    (location.fedexFreightAccountType === "FXFM" ||
                        (location.fedexBillToAccountType === "FXFE" &&
                            location.thirdparty)) &&
                    !location.isFreightDirect &&
                    !location.isFreightBox
                )
            }
            if (isFXFM) {
                return (
                    (location.fedexFreightAccountType === "FXFC" ||
                        (location.fedexBillToAccountType === "FXFE" &&
                            location.thirdparty)) &&
                    !location.isFreightDirect &&
                    !location.isFreightBox
                )
            }

            if (IsAliasInboundAccount && isFXFE_Shipper) {
                if (isCanadaToMexico) {
                    return (
                        (location.fedexFreightAccountType === "FXFM" ||
                            (location.fedexBillToAccountType === "FXFE" &&
                                location.thirdparty) ||
                            (location.fedexFreightAccountType === "FXFE" &&
                                mode === "outbound")) &&
                        !location.isFreightDirect &&
                        !location.isFreightBox
                    )
                }
                if (isMexicoToCanada) {
                    return (
                        (location.fedexFreightAccountType === "FXFC" ||
                            (location.fedexBillToAccountType === "FXFE" &&
                                location.thirdparty) ||
                            (location.fedexFreightAccountType === "FXFE" &&
                                mode === "outbound")) &&
                        !location.isFreightDirect &&
                        !location.isFreightBox
                    )
                }
            }
            return false
        }

        // Without `canadaMexicoLocationModal` status
        const standardFilterCondition = location => {
            if (isFXFC || isFXFE_ThirdParty) {
                return (
                    location.fedexFreightAccountType === "FXFM" ||
                    location.fedexBillToAccountType === "FXFM"
                )
            }
            if (isFXFM) {
                return (
                    location.fedexFreightAccountType === "FXFC" ||
                    location.fedexBillToAccountType === "FXFC" ||
                    location.fedexBillToAccountType === "FXFE"
                )
            }
            return false
        }

        const filterCondition = canadaMexicoLocationModal
            ? freightFilterCondition
            : standardFilterCondition

        return formattedLocations.filter(filterCondition)
    }

    const onBillToLocationChange = option => {
        const selectedLocation = locations.find(
            location => location?.cpgCode === option?.value
        )
        changeField("secondSelectedLocation", selectedLocation)
    }

    const nextStepCloseModal = () => {
        beforeNextStep()
        setLocationModalOpen(false)
    }

    const locationValidationCheck = (
        secondSelectedLocation,
        locationToValidate
    ) => {
        const addressMandatoryKeys = ["city", "postalCode", "state", "country"]
        for (const [key, value] of Object.entries(locationToValidate.address)) {
            if (
                addressMandatoryKeys.includes(key) &&
                value &&
                value.toLowerCase() !==
                    secondSelectedLocation?.address[key]?.toLowerCase()
            ) {
                return false
            }
        }
        return true
    }

    const overWriteInvalidEntry = (
        locationErrorObject,
        secondSelectedLocation,
        locationErrorPath
    ) => {
        if (
            locationErrorObject.name !==
            secondSelectedLocation.billingContact?.companyName
        ) {
            changeField(
                `${locationErrorPath}.name`,
                secondSelectedLocation.billingContact?.companyName
            )
        }
        for (const [key, value] of Object.entries(
            locationErrorObject.address
        )) {
            if (value && value !== secondSelectedLocation.address[key]) {
                changeField(
                    `${locationErrorPath}.address.${key}`,
                    secondSelectedLocation.address[key]
                )
            }
        }
    }

    if (!isInvalidLocationEntry) {
        return (
            <Grid
                item
                container
                className={classes.paper}
                alignContent="flex-start"
            >
                <Grid
                    item
                    container
                    direction="column"
                    alignContent="flex-start"
                    className={classes.mainContainer}
                >
                    <Grid item container direction="column">
                        <Typography variant="h6" align="center">
                            <FormattedMessage
                                id="destination.secondLocationModal__selectLocationTitle"
                                defaultMessage="This shipment requires two account numbers"
                            />
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                            <FormattedMessage
                                id="destination.secondLocationModal__selectLocationSubtitle"
                                defaultMessage="Please select a second account for this shipment"
                            />
                        </Typography>
                    </Grid>
                    <Grid item container className={classes.section}>
                        {modalOptions.length > 0 ? (
                            <Field
                                component={FormSelectBillTo}
                                options={modalOptions}
                                name="billToLocationSecondAccount"
                                label={
                                    <FormattedMessage
                                        id="destination.secondLocationModal__selectLocation"
                                        defaultMessage="Select Location"
                                    />
                                }
                                onChange={option => {
                                    onBillToLocationChange(option)
                                }}
                            />
                        ) : (
                            <Typography color="error">
                                <FormattedMessage
                                    id="destination.secondLocationModal__noOptions"
                                    defaultMessage="You do not have the proper account type for this shipment"
                                />
                            </Typography>
                        )}
                    </Grid>
                    <Grid
                        item
                        container
                        className={classes.buttonContainer}
                        alignItems="flex-end"
                        justify="flex-end"
                    >
                        <Button
                            variant="contained"
                            color="primary"
                            id="secondLocationSelectedNextButton"
                            disabled={
                                !secondBillToLocation || !secondSelectedLocation
                            }
                            onClick={() => {
                                if (
                                    secondSelectedLocation?.fedexFreightAccount &&
                                    secondSelectedLocation?.isShipperEnabled
                                ) {
                                    const isValidLocationInput = locationValidationCheck(
                                        secondSelectedLocation,
                                        invalidLocationEntryOrigin[2]
                                    )
                                    if (isValidLocationInput) {
                                        nextStepCloseModal()
                                    } else {
                                        setIsInvalidLocationEntry(true)
                                    }
                                } else {
                                    nextStepCloseModal()
                                }
                            }}
                        >
                            <FormattedMessage
                                id="secondLocation__nextButton"
                                defaultMessage="Next"
                            />
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        )
    } else {
        return (
            <Grid item container className={classes.paper}>
                <Grid
                    item
                    container
                    direction="column"
                    className={classes.mainContainer}
                >
                    <Grid item container direction="column">
                        <Typography variant="h6" align="center">
                            <FormattedMessage
                                id="destination.secondLocationModal__invalidLocationEntryTitle"
                                defaultMessage="In order to get rates, the {errorOrigin} address needs to match the location on file"
                                values={{
                                    errorOrigin: invalidLocationEntryOrigin[0],
                                }}
                            />
                        </Typography>
                        <Typography variant="subtitle1" align="center">
                            <FormattedMessage
                                id="destination.secondLocationModal__invalidLocationEntrySubTitle"
                                defaultMessage="Would you like to use your selected location as your {errorOrigin} address?"
                                values={{
                                    errorOrigin: invalidLocationEntryOrigin[0],
                                }}
                            />
                        </Typography>
                    </Grid>
                    <Grid
                        item
                        container
                        direction="row"
                        justify="center"
                        className={classes.section}
                        alignItems="center"
                    >
                        <Grid item container direction="column" xs={4}>
                            <Grid item>
                                <Typography
                                    variant="subtitle2"
                                    align="center"
                                    className={classes.underLineText}
                                >
                                    <FormattedMessage
                                        id="destination.secondLocationModal__errorOrigin"
                                        defaultMessage="Current address:"
                                    />
                                </Typography>
                            </Grid>

                            <Grid item className={classes.smallSection}>
                                {displayAddressMismatchDetails && (
                                    <React.Fragment>
                                        <Typography
                                            variant="body2"
                                            align="center"
                                        >
                                            {invalidLocationEntryOrigin[2].name}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            align="center"
                                        >
                                            {invalidLocationEntryOrigin[2]
                                                .address.street1 ??
                                                invalidLocationEntryOrigin[2]
                                                    .address.street2}
                                        </Typography>
                                    </React.Fragment>
                                )}
                                <Typography variant="body2" align="center">
                                    {`${invalidLocationEntryOrigin[2].address.city}, ${invalidLocationEntryOrigin[2].address.state}`}
                                </Typography>
                                <Typography variant="body2" align="center">
                                    {
                                        invalidLocationEntryOrigin[2].address
                                            .postalCode
                                    }
                                </Typography>
                                <Typography variant="body2" align="center">
                                    {
                                        invalidLocationEntryOrigin[2].address
                                            .country
                                    }
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item>
                            <ArrowForwardIcon
                                fontSize="large"
                                className={classes.forwardIcon}
                            />
                        </Grid>
                        <Grid item container direction="column" xs={4}>
                            <Grid item>
                                <Typography
                                    variant="subtitle2"
                                    align="center"
                                    className={classes.underLineText}
                                >
                                    <FormattedMessage
                                        id="destination.secondLocationModal__locationAddress"
                                        defaultMessage="Location address:"
                                    />
                                </Typography>
                            </Grid>
                            <Grid item className={classes.smallSection}>
                                {displayAddressMismatchDetails && (
                                    <React.Fragment>
                                        <Typography
                                            variant="body2"
                                            align="center"
                                        >
                                            {
                                                secondSelectedLocation
                                                    .billingContact?.companyName
                                            }
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            align="center"
                                        >
                                            {secondSelectedLocation.address
                                                .street1 ??
                                                secondSelectedLocation.address
                                                    .street2}
                                        </Typography>
                                    </React.Fragment>
                                )}
                                <Typography variant="body2" align="center">
                                    {`${secondSelectedLocation.address.city}, ${secondSelectedLocation.address.state}`}
                                </Typography>
                                <Typography variant="body2" align="center">
                                    {secondSelectedLocation.address.postalCode}
                                </Typography>
                                <Typography variant="body2" align="center">
                                    {secondSelectedLocation.address.country}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid
                        item
                        container
                        className={classes.buttonContainer}
                        alignItems="flex-end"
                        justify="flex-end"
                        direction="row"
                    >
                        <Grid item xs={6}>
                            <Button
                                variant="outlined"
                                color="secondary"
                                id="secondLocationCancelInvalidEntry"
                                onClick={() => setLocationModalOpen(false)}
                            >
                                <FormattedMessage
                                    id="secondLocation_overWriteCancel"
                                    defaultMessage="Cancel"
                                />
                            </Button>
                        </Grid>
                        <Grid container item xs={6} justify="flex-end">
                            <Button
                                variant="contained"
                                color="primary"
                                id="secondLocationOverWriteInvalidEntryButton"
                                onClick={() => {
                                    overWriteInvalidEntry(
                                        invalidLocationEntryOrigin[2],
                                        secondSelectedLocation,
                                        invalidLocationEntryOrigin[1]
                                    )
                                    nextStepCloseModal()
                                }}
                            >
                                <FormattedMessage
                                    id="secondLocation__overWrite"
                                    defaultMessage="Confirm"
                                />
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        )
    }
}

const mapStateToProps = state => {
    const secondBillToLocation =
        state?.form?.bookShipment?.values?.billToLocationSecondAccount ||
        state?.form?.quickRate?.values?.billToLocationSecondAccount
    const secondSelectedLocation =
        state?.form?.bookShipment?.values?.secondSelectedLocation ||
        state?.form?.quickRate?.values?.secondSelectedLocation

    return {
        secondBillToLocation,
        secondSelectedLocation,
    }
}

export default withStyles(styles)(connect(mapStateToProps)(SecondLocationModal))
