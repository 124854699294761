/* eslint-disable react/prop-types */
import React from "react"
import TextField from "@material-ui/core/TextField"
import { get } from "lodash"

const FormikTextField = ({
    field,
    field: { name },
    form: { touched, errors },
    label,
    placeholder,
    classes,
    ...props
}) => {
    const error = get(errors, name)
    const inErrorState = !!error && !!get(touched, name)

    return (
        <TextField
            {...field}
            label={label}
            placeholder={placeholder}
            error={inErrorState}
            helperText={inErrorState ? error : ""}
            {...props}
            value={field.value}
            type="text"
        />
    )
}

export default FormikTextField
