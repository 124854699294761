import React, { Component, Fragment } from "react"
import PropTypes from "prop-types"
import Iframe from "react-iframe"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import CircularProgress from "@material-ui/core/CircularProgress"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"

const styles = () => ({
    tutorial: {
        width: "100%",
        height: "auto",
        backgroundColor: "#FFFFFF",
        padding: "10px",
        minHeight: "580px",
    },
    hide: {
        display: "none",
    },
})

class Tutorial extends Component {
    state = {
        show: false,
    }

    availableTutorials = [
        "RDWY",
        "PITD",
        "AACT",
        "AVRT",
        "CENF",
        "CTII",
        "CNWY",
        "DHRN",
        "EXLA",
        "HMES",
        "NPME",
        "ODFL",
        "RLCA",
        "RDFS",
        "SAIA",
        "SEFL",
        "UPGF",
        "WARD",
        "ABFS",
        "XPOL",
    ]

    show = () => {
        this.setState({ show: true })
    }

    render() {
        const { code, classes } = this.props
        const { show } = this.state
        const tutorialURL = `/tutorials/${code}/${code}.html`

        return (
            <Fragment>
                {show && this.availableTutorials.includes(code) && (
                    <Grid item container>
                        <Typography variant="subheading">
                            <FormattedMessage
                                id="tutorial.title"
                                defaultMessage="Carrier Setup Instructions"
                            />
                        </Typography>
                    </Grid>
                )}
                {!show && this.availableTutorials.includes(code) && (
                    <Grid item container justify="center">
                        <CircularProgress size={40} color="primary" />
                    </Grid>
                )}
                <div className={show ? classes.tutorial : classes.hide}>
                    {this.availableTutorials.includes(code) && (
                        <Iframe
                            onLoad={() => this.show()}
                            title="tutorial"
                            id="tutorial"
                            display="initial"
                            url={tutorialURL}
                            width="100%"
                            height="100%"
                            frameBorder="0"
                            position="relative"
                        />
                    )}
                </div>
            </Fragment>
        )
    }
}

Tutorial.propTypes = {
    classes: PropTypes.object.isRequired,
    code: PropTypes.string.isRequired,
}

export default withStyles(styles)(Tutorial)
