/* eslint-disable arrow-parens */
import React, { Component } from "react"
import { FormattedMessage } from "react-intl"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import CarrierCell from "./carrierCell"

const styles = theme => ({
    root: {
        marginTop: "85px",
    },
    table: {
        minWidth: 700,
    },
    row: {
        "&:hover": {
            cursor: "pointer",
            backgroundColor: "#D4D4D4",
        },
        "&:active": {
            cursor: "pointer",
            backgroundColor: theme.palette.secondary.light,
        },
    },
    instructions: {
        paddingLeft: "10px",
        paddingTop: "10px",
    },
})

class AddCarrierTable extends Component {
    render() {
        const { classes, onCarrierSelect, carrierList } = this.props

        return (
            <div className={classes.root}>
                <Typography
                    variant="subheading"
                    color="primary"
                    className={classes.instructions}
                >
                    <FormattedMessage
                        id="addCarrier.table.title"
                        defaultMessage="Choose a carrier below."
                    />
                </Typography>
                <Grid container>
                    {carrierList.map(carrier => (
                        <CarrierCell
                            onCarrierSelect={onCarrierSelect}
                            key={carrier.name}
                            carrier={carrier}
                        />
                    ))}
                </Grid>
            </div>
        )
    }
}

AddCarrierTable.propTypes = {
    carrierList: PropTypes.array.isRequired,
    classes: PropTypes.object.isRequired,
    onCarrierSelect: PropTypes.func.isRequired,
}

export default withStyles(styles)(AddCarrierTable)
