import React, { useState } from "react"
import { Grid, Button, Typography } from "@material-ui/core"
import { get } from "lodash"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogTitle from "@material-ui/core/DialogTitle"
import { Field } from "redux-form"
import FormCheckbox from "../../form/form-checkbox"
import { FormattedMessage } from "react-intl"
import RedirectP44Dialog from "./RedirectP44Dialog"
import { useFlags } from "launchdarkly-react-client-sdk"
import { useHistory } from "react-router-dom"

const Carriers = ({
    dialogOpen,
    location,
    owner,
    handleClickOpen,
    classes,
    handleClose,
    carrierList,
    userOnboardingDNA,
    setDialogOpen,
    gaCategory,
    trackGA,
}) => {
    const [carrierCode, setCarrierCode] = useState(null)
    const [carrierAccountId, setCarrierAccountId] = useState(null)
    const { enableMulticarrierSignupOnLtlselect } = useFlags()
    const history = useHistory()

    const relevantCarriers =
        location.carriers == null
            ? []
            : location.carriers.filter(it => it.carrier !== "FXFE")

    return (
        <Grid item container direction="column">
            <Grid
                item
                container
                justify="space-between"
                alignItems="center"
                alignContent="center"
                className={classes.location__card__header}
            >
                <Grid item container alignItems="center" xs={4}>
                    <Typography gutterBottom variant="h6">
                        <FormattedMessage
                            id="locations.carriers__title"
                            defaultMessage="Carriers"
                        />
                    </Typography>
                </Grid>
                {owner && (
                    <Grid
                        item
                        container
                        xs={8}
                        alignItems="center"
                        justify="flex-end"
                    >
                        <Button
                            onClick={() => {
                                if (!userOnboardingDNA) {
                                    setDialogOpen(true)
                                } else {
                                    handleClickOpen()
                                }
                                trackGA(gaCategory, "Add Carrier Click")
                            }}
                            color="primary"
                            id="addCarrierButton"
                        >
                            <FormattedMessage
                                id="locations.carriers__addCarrier"
                                defaultMessage="Add Carrier"
                            />
                        </Button>
                    </Grid>
                )}
            </Grid>
            {/*{carriers.length < 1 && <Grid item container justify="center"><CircularProgress size={48} color="secondary" /></Grid>}*/}
            {relevantCarriers.map((carrier, index) =>
                owner ? (
                    <Grid
                        item
                        container
                        className={
                            index === relevantCarriers.length - 1
                                ? classes.location__row__last
                                : classes.location__row
                        }
                        alignItems="center"
                        key={index}
                        onClick={() => {
                            if (!userOnboardingDNA) {
                                setDialogOpen(true)
                                setCarrierCode(carrier.carrier)
                                setCarrierAccountId(carrier.accountId)
                            } else {
                                handleClickOpen(
                                    carrier.carrier,
                                    carrier.accountId
                                )
                            }
                        }}
                    >
                        <Typography variant="body2" color="inherit">
                            {" "}
                            {get(
                                carrierList,
                                ["carriers", carrier.carrier, "name"],
                                ""
                            )}{" "}
                        </Typography>
                    </Grid>
                ) : (
                    <Grid
                        item
                        container
                        className={
                            index === relevantCarriers.length - 1
                                ? classes.location__row__last__noHover
                                : classes.location__row__noHover
                        }
                        alignItems="center"
                        key={index}
                    >
                        <Typography variant="body2" color="inherit">
                            {" "}
                            {get(
                                carrierList,
                                ["carriers", carrier.carrier, "name"],
                                ""
                            )}{" "}
                        </Typography>
                    </Grid>
                )
            )}
            <RedirectP44Dialog
                dialogOpen={dialogOpen}
                handleClose={handleClose}
                gaCategory={gaCategory}
                onContinue={() => {
                    handleClickOpen(carrierCode, carrierAccountId)
                    setCarrierCode(null)
                    setCarrierAccountId(null)
                    trackGA(
                        gaCategory,
                        "Redirect Notice Modal - Continue Click"
                    )
                }}
            />
        </Grid>
    )
}

export default Carriers
