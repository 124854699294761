import React from "react"
import {
    Collapse,
    Grid,
    IconButton,
    Typography,
    withStyles,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { Field } from "redux-form"
import FormField from "../../../form/form-field"
import FormSelectAutocomplete from "../../../form/form-select-autocomplete"
import ExpandMoreIcon from "@material-ui/icons/ExpandMore"
import ExpandLessIcon from "@material-ui/icons/ExpandLess"
import { trackGAEvent } from "../../../../actions/user"
import { useDispatch } from "react-redux"

const styles = theme => ({
    declaredValueSibling: {
        minWidth: "48px",
    },
    collapse: {
        width: "100%",
    },
    helper: {
        textDecoration: "underline",
        paddingRight: "20px",
        color: theme.palette.primary.main,
        "&:hover": {
            color: theme.palette.secondary.main,
            cursor: "pointer",
        },
    },
})

const conditionOfGoodsOptions = [
    {
        label: (
            <FormattedMessage
                id="book.declaredValue__new"
                defaultMessage="New"
            />
        ),
        value: "NEW",
    },
    {
        label: (
            <FormattedMessage
                id="book.declaredValue__usedOrReconditioned"
                defaultMessage="Used or Reconditioned"
            />
        ),
        value: "USED_OR_RECONDITIONED",
    },
]

const DeclaredValue = ({
    category,
    preferredCurrencyCode,
    weightMeasurementUnit,
    declaredValueOptions,
    changeField,
    declaredValueExpanded,
    classes,
}) => {
    const dispatch = useDispatch()
    return (
        <Grid item container>
            <Grid item container justify="space-between">
                <Grid item className={classes.declaredValueSibling} />
                <Grid
                    item
                    container
                    xs={8}
                    alignItems="center"
                    justify="center"
                >
                    <Typography variant="subtitle1" align="center">
                        <FormattedMessage
                            id="book.declaredValue__title"
                            defaultMessage="Declared Value (Optional, additional charges apply)"
                        />
                    </Typography>
                </Grid>

                <Grid item className={classes.declaredValueSibling}>
                    <IconButton
                        onClick={() => {
                            changeField(
                                "declaredValueExpanded",
                                !declaredValueExpanded
                            )

                            if (!declaredValueExpanded) {
                                changeField("declaredValueOptions", {})
                            }
                        }}
                    >
                        {declaredValueExpanded ? (
                            <ExpandLessIcon />
                        ) : (
                            <ExpandMoreIcon />
                        )}
                    </IconButton>
                </Grid>
            </Grid>
            <Grid item container>
                <Collapse
                    in={declaredValueExpanded}
                    className={classes.collapse}
                >
                    <Grid item container justify="center">
                        <a
                            rel="noopener noreferrer"
                            href="https://www.fedex.com/en-us/shipping/declared-value.html"
                            target="_blank"
                            onClick={() => {
                                dispatch(
                                    trackGAEvent(
                                        "Book Shipment - Finalize",
                                        "Declared Value Helper Click"
                                    )
                                )
                            }}
                        >
                            <Typography
                                variant="caption"
                                className={classes.helper}
                            >
                                <FormattedMessage
                                    id="finalize__declaredValueHelper"
                                    defaultMessage="What is declared value?"
                                />
                            </Typography>
                        </a>
                    </Grid>
                    <Grid item container>
                        <Grid item container xs={4}>
                            <Field
                                category={category}
                                name="declaredValueOptions.declaredValue"
                                label={
                                    <FormattedMessage
                                        id="book.declaredValue__declaredValueAmount"
                                        defaultMessage="Declared Value per {weightMeasurementUnit} ({preferredCurrencyCode})"
                                        values={{
                                            preferredCurrencyCode,
                                            weightMeasurementUnit,
                                        }}
                                    />
                                }
                                component={FormField}
                            />
                        </Grid>
                        <Grid item container xs={4}>
                            <Field
                                category={category}
                                name="declaredValueOptions.conditionsOfGoods"
                                label={
                                    <FormattedMessage
                                        id="book.declaredValue__conditionOfGoods"
                                        defaultMessage="Condition of Goods"
                                    />
                                }
                                component={FormSelectAutocomplete}
                                options={conditionOfGoodsOptions}
                            />
                        </Grid>
                        {declaredValueOptions.conditionsOfGoods === "NEW" && (
                            <Grid item container xs={4}>
                                <Field
                                    category={category}
                                    name="declaredValueOptions.coverageAmount"
                                    label={
                                        <FormattedMessage
                                            id="book.declaredValue__excessLiability"
                                            defaultMessage="Excess Liability per {weightMeasurementUnit} ({preferredCurrencyCode})"
                                            values={{
                                                preferredCurrencyCode,
                                                weightMeasurementUnit,
                                            }}
                                        />
                                    }
                                    component={FormField}
                                    required={
                                        declaredValueOptions.conditionsOfGoods ===
                                        "NEW"
                                    }
                                />
                            </Grid>
                        )}
                    </Grid>
                </Collapse>
            </Grid>
        </Grid>
    )
}

export default withStyles(styles)(DeclaredValue)
