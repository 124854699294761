/* eslint-disable arrow-parens */
import React, { Component } from "react"
import PropTypes from "prop-types"
import { get } from "lodash"
import Grid from "@material-ui/core/Grid"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import Typography from "@material-ui/core/Typography"
import { withStyles } from "@material-ui/core/styles"
import { FormattedMessage } from "react-intl"
import { Button } from "@material-ui/core"

const styles = theme => ({
    root: {
        position: "fixed",
        top: 0,
        left: 0,
        width: "100%",
        zIndex: 1,
    },
    title: {
        "&:hover": {
            color: theme.palette.secondary.main,
            cursor: "pointer",
        },
        "&:active": {
            color: theme.palette.secondary.light,
            cursor: "pointer",
        },
    },
    logo: {
        width: "140px",
        height: "auto",
    },
    helper__text: {
        paddingTop: "5px",
        paddingBottom: "5px",
        paddingRight: "15px",
        backgroundColor: "#F2F2F2",
    },
    carrierNotListed: {
        paddingRight: "4px",
    },
})

class Header extends Component {
    render() {
        const { classes, billToLocation, history } = this.props

        return (
            <div className={classes.root}>
                <AppBar position="static" color="primary">
                    <Toolbar>
                        <Grid container>
                            <Grid item container xs={1} alignItems="center">
                                <Button
                                    onClick={() => {
                                        history.push("/home")
                                    }}
                                    id="navigation__homeButton"
                                >
                                    <img
                                        className={classes.logo}
                                        alt="LTL Select"
                                        src="static/LTLselect-logo-type-new.png"
                                    />
                                </Button>
                            </Grid>
                            <Grid item container xs={1} />
                            {billToLocation && (
                                <Grid
                                    item
                                    container
                                    xs={6}
                                    justify="flex-start"
                                    alignItems="center"
                                >
                                    <Typography color="inherit">
                                        {` ${get(
                                            billToLocation,
                                            "data.contact.companyName",
                                            ""
                                        )} - 
                                    ${get(
                                        billToLocation,
                                        "data.address.addressLines[0]",
                                        ""
                                    )},
                                    ${get(
                                        billToLocation,
                                        "data.address.state",
                                        ""
                                    )} 
                                    ${get(
                                        billToLocation,
                                        "data.address.postalCode",
                                        ""
                                    )}`}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    </Toolbar>
                </AppBar>
                <Grid
                    item
                    container
                    justify="flex-end"
                    className={classes.helper__text}
                >
                    {this.props.mode === "add" && (
                        <Typography
                            variant="caption"
                            className={classes.carrierNotListed}
                        >
                            <FormattedMessage
                                id="header.carrierNotListed"
                                defaultMessage="Carrier not listed?"
                            />
                        </Typography>
                    )}
                    <Typography variant="caption">
                        <FormattedMessage
                            id="header.help"
                            defaultMessage="Need help? Email {link}"
                            values={{
                                link: (
                                    <a href="mailto:support@ltlselect.com">
                                        support@ltlselect.com
                                    </a>
                                ),
                            }}
                        />
                    </Typography>
                </Grid>
            </div>
        )
    }
}

Header.propTypes = {
    classes: PropTypes.object.isRequired,
    mode: PropTypes.bool.isRequired,
    billToLocation: PropTypes.object.isRequired,
}

export default withStyles(styles)(Header)
