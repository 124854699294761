import React, { Fragment, useEffect, useState } from "react"
import {
    Button,
    Card,
    CardContent,
    CardHeader,
    Typography,
    withStyles,
} from "@material-ui/core"
import { FormattedMessage } from "react-intl"
import { connect } from "react-redux"
import PricingTable from "./PricingTable"
import PuertoRicoPricingModal from "./PuertoRicoPricingModal"
import { getPuertoRicoPricing } from "../../../../actions/offshoreRating"
import PricingTableSimple from "./PricingTableSimple"
import { styles } from "./styles"
import NavigationDropdown from "./NavigationDropdown"

const PuertoRicoPricingTile = ({
    classes,
    getPuertoRicoPricing,
    defaultPricing,
    customPricing,
    location,
    trackGA,
}) => {
    const [activeFilter, setActiveFilter] = useState("oceanCharges")
    const [editPricingOpen, setEditPricingOpen] = useState(false)

    useEffect(() => {
        getPuertoRicoPricing()
    }, [])

    return (
        <Fragment>
            <PuertoRicoPricingModal
                trackGA={trackGA}
                classes={classes}
                activeFilter={activeFilter}
                setActiveFilter={setActiveFilter}
                open={editPricingOpen}
                setOpen={setEditPricingOpen}
                defaultPricing={defaultPricing}
                customPricing={customPricing}
                location={location}
            />
            <Card className={classes.card}>
                <CardHeader
                    classes={{ action: classes.card__action }}
                    title={
                        <Typography variant="h6">
                            <FormattedMessage
                                id="locations.puertoRicoPricing"
                                defaultMessage="Puerto Rico Pricing"
                            />
                        </Typography>
                    }
                    action={
                        <Button
                            color="primary"
                            id="puertoRicoCard__editRates"
                            onClick={() => {
                                setEditPricingOpen(true)
                                trackGA(
                                    "Puerto Rico",
                                    "Opened Puerto Rico Pricing",
                                    "Edit Pricing"
                                )
                            }}
                        >
                            <FormattedMessage
                                id="locations.puertoRicoCard__editPricing"
                                defaultMessage="Edit Pricing"
                            />
                        </Button>
                    }
                />
                <CardContent classes={{ root: classes.card__content }}>
                    <NavigationDropdown
                        activeFilter={activeFilter}
                        setActiveFilter={setActiveFilter}
                        classes={classes}
                        offshoreState={"PR"}
                    />
                    {activeFilter === "oceanCharges" ? (
                        <PricingTable
                            open={editPricingOpen}
                            classes={classes}
                            titleColumn={"gateway"}
                            defaultPricing={defaultPricing?.oceanCharges}
                            customPricing={
                                location?.customPuertoRicoPricing?.oceanCharges
                            }
                            columns={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.gateway"
                                            defaultMessage="Gateway"
                                        />
                                    ),
                                    value: "gateway",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.grossDiscount"
                                            defaultMessage="Gross Discount"
                                        />
                                    ),
                                    value: "oceanGrossDiscount",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.ratePerCuFt"
                                            defaultMessage="Rate per CuFt"
                                        />
                                    ),
                                    value: "ratePerCuFt",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.ratePerCwt"
                                            defaultMessage="Rate per CWT"
                                        />
                                    ),
                                    value: "ratePerCwt",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.minimumCharge"
                                            defaultMessage="Minimum Charge"
                                        />
                                    ),
                                    value: "minimumCharge",
                                },
                            ]}
                        />
                    ) : null}

                    {activeFilter === "absoluteMinimumCharge" ? (
                        <PricingTableSimple
                            classes={classes}
                            defaultPricing={
                                defaultPricing?.absoluteMinimumCharge
                            }
                            customPricing={customPricing?.absoluteMinimumCharge}
                            rowValues={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.absoluteMinimumCharge"
                                            defaultMessage="Custom Absolute Minimum Charge"
                                        />
                                    ),
                                    value: "charge",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.grossDiscount"
                                            defaultMessage="Gross Discount"
                                        />
                                    ),
                                    value: "grossPercentageDiscount",
                                },
                            ]}
                        />
                    ) : null}

                    {activeFilter === "islandExtremeLengthCharges" ? (
                        <PricingTable
                            open={editPricingOpen}
                            classes={classes}
                            defaultPricing={
                                defaultPricing?.islandExtremeLengthCharges
                            }
                            customPricing={
                                customPricing?.islandExtremeLengthCharges
                            }
                            titleColumn={"zone"}
                            columns={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.zone"
                                            defaultMessage="Zone"
                                        />
                                    ),
                                    value: "zone",
                                },

                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.charge"
                                            defaultMessage="Charge"
                                        />
                                    ),
                                    value: "charge",
                                },
                            ]}
                        />
                    ) : null}

                    {activeFilter === "customerPickupAndDeliveryCharge" ? (
                        <PricingTableSimple
                            classes={classes}
                            defaultPricing={
                                defaultPricing?.customerPickupAndDeliveryCharge
                            }
                            customPricing={
                                customPricing?.customerPickupAndDeliveryCharge
                            }
                            rowValues={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.ratePerCuFt"
                                            defaultMessage="Rate per CuFt"
                                        />
                                    ),
                                    value: "ratePerCuFt",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.ratePerCwt"
                                            defaultMessage="Rate per CWT"
                                        />
                                    ),
                                    value: "ratePerCwt",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.minimumCharge"
                                            defaultMessage="Minimum Charge"
                                        />
                                    ),
                                    value: "minimumCharge",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.grossDiscount"
                                            defaultMessage="Gross Discount"
                                        />
                                    ),
                                    value: "grossPercentageDiscount",
                                },
                            ]}
                        />
                    ) : null}
                    {activeFilter === "domesticDockDropFee" ? (
                        <PricingTableSimple
                            classes={classes}
                            defaultPricing={defaultPricing?.domesticDockDropFee}
                            customPricing={customPricing?.domesticDockDropFee}
                            rowValues={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.ratePerCwt"
                                            defaultMessage="Rate per CWT"
                                        />
                                    ),
                                    value: "ratePerCwt",
                                },

                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.minimumCharge"
                                            defaultMessage="Minimum Charge"
                                        />
                                    ),
                                    value: "minimumCharge",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.maximumCharge"
                                            defaultMessage="Maximum Charge"
                                        />
                                    ),
                                    value: "maximumCharge",
                                },
                            ]}
                        />
                    ) : null}
                    {activeFilter === "otherFees" ? (
                        <PricingTableSimple
                            classes={classes}
                            defaultPricing={{
                                documentationFee:
                                    defaultPricing?.documentationFee,
                                sedFee: defaultPricing?.sedFee,
                                hazMatFee: defaultPricing?.hazMatFee,
                            }}
                            customPricing={{
                                documentationFee:
                                    customPricing?.documentationFee,
                                sedFee: customPricing?.sedFee,
                                hazMatFee: customPricing?.hazMatFee,
                            }}
                            rowValues={[
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.documentationFee"
                                            defaultMessage="Documentation Fee"
                                        />
                                    ),
                                    value: "documentationFee",
                                },

                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.sedFee"
                                            defaultMessage="SED Fee"
                                        />
                                    ),
                                    value: "sedFee",
                                },
                                {
                                    title: (
                                        <FormattedMessage
                                            id="location.offshore.hazMatFee"
                                            defaultMessage="Hazardous Materials Fee"
                                        />
                                    ),
                                    value: "hazMatFee",
                                },
                            ]}
                        />
                    ) : null}
                </CardContent>
            </Card>
        </Fragment>
    )
}

const mapStateToProps = (state, props) => {
    const defaultPricing = state?.offshoreRating?.puertoRicoPricing ?? {}
    const customPricing =
        state?.form?.location?.values?.customPuertoRicoPricing ?? {}
    return {
        defaultPricing,
        customPricing,
    }
}

const mapDispatchToProps = dispatch => ({
    getPuertoRicoPricing: state => dispatch(getPuertoRicoPricing(state)),
})

export default withStyles(styles)(
    connect(mapStateToProps, mapDispatchToProps)(PuertoRicoPricingTile)
)
