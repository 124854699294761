import { get, merge } from "lodash"
import { defineMessages, FormattedMessage } from "react-intl"
import { combineValidators, createValidator } from "revalidate"
import composeValidators from "revalidate/lib/composeValidators"
import {
    countryPhoneValidator,
    emailValidator,
    hasHazMatIdPrefix,
    hasLength,
    hasLengthGreaterThan,
    hasLengthLessThan,
    isConsigneeImporter,
    isGreaterThan,
    isInternationalShipment,
    isNumeric,
    isOneOf,
    isOneOfSilent,
    isRequired,
    isRequiredIf,
    isUSDomesticOffshoreShipmentWithItemCustoms,
    nmfcSubcodeValidator,
    nmfcValidator,
    requiredNaftaDeclaration,
    packageTypeValidator,
    isDecimalNumeric,
    isValidCountryPostalCode,
    pickUpDateValidator,
    aesNumberValidator,
    customReferencesValidation,
    itemPropertiesFormValidator,
    isToOrFromMexicoShipment,
    isAlphaNumericOnly,
} from "../../../actions/validation"
import {
    customsCOOValidation,
    hazmatValidator,
    isRequiredIfNonDomesticOrOffshoreWithItemCustoms,
} from "../../book"
import {
    isRequiredIfFreightDirectReturnsStandardOrBasicwAppt,
    isRequiredIfPickedUp,
    pickupWindowValidator,
} from "../../book/pickup"
import {
    freightClassValues,
    hazClassValues,
    pkgGrpValues,
    hazContainersValues,
} from "../../quotesPage/quotesDrawer"
import { usmcaFormValidation } from "../../documents/USMCA/validation"

const messages = defineMessages({
    nameField: {
        id: "generalTerms__companyName",
        defaultMessage: "Company Name",
    },
    address1OrAddress2Field: {
        id: "generalTerms__address1OrAddress2",
        defaultMessage: "Address 1 or Address 2",
    },
    cityField: { id: "generalTerms__city", defaultMessage: "City" },
    stateField: { id: "generalTerms__state", defaultMessage: "State" },
    countryField: { id: "generalTerms__country", defaultMessage: "Country" },
    postalCodeField: {
        id: "generalTerms__postalCode",
        defaultMessage: "Postal Code",
    },
    contactNameField: {
        id: "generalTerms__contactName",
        defaultMessage: "Contact Name",
    },
    phoneField: {
        id: "generalTerms__contactPhone",
        defaultMessage: "Contact Phone",
    },
    extensionField: {
        id: "generalTerms__contactExtension",
        defaultMessage: "Contact Extension",
    },
    emailField: {
        id: "generalTerms__contactEmail",
        defaultMessage: "Contact Email",
    },
    pickupWindowValidator: {
        id: "orderDetails.validation__pickupWindow",
        defaultMessage:
            "{field} should be at least 90 minutes after Ready Time",
    },
    pickupReadyField: {
        id: "generalTerms.pickUpInformation__readyTime",
        defaultMessage: "Ready Time",
    },
    pickupCloseField: {
        id: "generalTerms.pickUpInformation__closeTime",
        defaultMessage: "Close Time",
    },
    pickUpWindow: {
        id: "generalTerms.pickUpInformation__pickupWindow",
        defaultMessage: "Pick up Window",
    },
    pickupNameField: {
        id: "generalTerms__name",
        defaultMessage: "Name",
    },
    pickupPhoneField: {
        id: "generalTerms__phone",
        defaultMessage: "Phone",
    },
    pickupExtensionField: {
        id: "generalTerms__extension",
        defaultMessage: "Extension",
    },
    pickupEmailField: {
        id: "generalTerms__email",
        defaultMessage: "Email",
    },
    invalidPostalCode: {
        id: "generalTerms__invalidPostalCode",
        defaultMessage: "Invalid postal code",
    },
})

const isRequiredIfStreet1MissingV2 = (prefix = "") =>
    isRequiredIf(
        values =>
            !get(
                values,
                prefix ? `${prefix}.address.street1` : "address.street1"
            )
    )

const isRequiredIfStreet2MissingV2 = (prefix = "") =>
    isRequiredIf(
        values =>
            !get(
                values,
                prefix ? `${prefix}.address.street2` : "address.street2"
            )
    )

export const isRequiredIfAddressEmpty = (prefix = "") => {
    return isRequiredIf(values => {
        return !values?.[prefix]?.shippingAddress?.address?.postalCode
    })
}

export const isRequiredIfIntlAndNoBrokerName = (prefix = "") =>
    isRequiredIf(values => {
        let origin = get(values, "origin.shippingAddress.address.country")
        let destination = get(
            values,
            "destination.shippingAddress.address.country"
        )

        if (prefix === "exportCustomsBrokerProfile") {
            return (
                ((origin === "MX" && destination === "CA") ||
                    (origin === "CA" && destination === "MX") ||
                    (origin === "MX" && destination === "US")) &&
                !values?.[prefix]?.brokerName
            )
        }

        return (
            isInternationalShipment(origin, destination) &&
            !values?.[prefix]?.brokerName
        )
    })

export const isRequiredBroker = (prefix = "") => {
    return isRequiredIf(values => {
        let origin = get(values, "origin.shippingAddress.address.country")
        let destination = get(
            values,
            "destination.shippingAddress.address.country"
        )

        if (prefix === "exportCustomsBrokerProfile") {
            return (
                (origin === "MX" && destination === "CA") ||
                (origin === "CA" && destination === "MX") ||
                (origin === "MX" && destination === "US")
            )
        }

        return isInternationalShipment(origin, destination)
    })
}

export const isRequiredIfIsAdvancedItems = isRequiredIf(
    values => values?.isAdvancedItems
)

const isRequiredIfIndividualHUWeight = isRequiredIf(values =>
    get(values, "handlingUnit[].isIndividualHUWeight")
)

const isRequiredIfNotIndividualHUWeight = isRequiredIf(
    values => !get(values, "handlingUnit[].isIndividualHUWeight")
)

export const addBrokerValidator = combineValidators({
    brokerName: isRequired({
        field: {
            id: "generalTerms__brokerName",
            defaultMessage: "Broker Name",
        },
    }),
    phone: {
        phone_number: composeValidators(
            isRequired,
            countryPhoneValidator("address.country", null, "US")
        )({
            field: {
                id: "generalTerms__phoneNumber",
                defaultMessage: "Phone Number",
            },
        }),
        extension: isNumeric({
            field: {
                id: "generalTerms__extension",
                defaultMessage: "Extension",
            },
        }),
    },
    email: {
        email_address: composeValidators(
            isRequired,
            emailValidator
        )({
            field: {
                id: "generalTerms__emailAddress",
                defaultMessage: "Email Address",
            },
        }),
    },
})

const postalCodeValidator = prefix =>
    createValidator(
        message => (value, allValues) => {
            const country = get(allValues, `${prefix}.address.country`)
            if (!isValidCountryPostalCode(value, country)) {
                return message
            }
        },
        field => ({ ...messages.invalidPostalCode, values: { field } })
    )

export const addressValidator = (prefix = "") => ({
    name: isRequired({ field: messages.nameField }),
    address: {
        street1: isRequiredIfStreet2MissingV2(prefix)({
            field: messages.address1OrAddress2Field,
        }),
        street2: isRequiredIfStreet1MissingV2(prefix)({
            field: messages.address1OrAddress2Field,
        }),
        city: isRequired({ field: messages.cityField }),
        state: isRequired({ field: messages.stateField }),
        postalCode: composeValidators(
            isRequired,
            postalCodeValidator(prefix)
        )({ field: messages.postalCodeField }),
        country: isRequired({ field: messages.countryField }),
    },
})

export const addressQuickRateValidator = (prefix = "") => ({
    address: {
        city: isRequired({ field: messages.cityField }),
        state: isRequired({ field: messages.stateField }),
        postalCode: composeValidators(
            isRequired,
            postalCodeValidator(prefix)
        )({ field: messages.postalCodeField }),
    },
})

export const contactValidator = country => ({
    name: composeValidators(
        isRequired,
        hasLengthGreaterThan(2)
    )({ field: messages.contactNameField }),
    phone: {
        phone_number: composeValidators(
            isRequired,
            countryPhoneValidator(country)
        )({
            field: messages.phoneField,
        }),
        extensionField: isNumeric({
            field: messages.extensionField,
        }),
    },
    email: {
        email_address: composeValidators(
            isRequired,
            emailValidator
        )({
            field: messages.emailField,
        }),
    },
})

const isRequiredIfIntl = isRequiredIf(values =>
    isInternationalShipment(
        get(values, "origin.shippingAddress.address.country"),
        get(values, "destination.shippingAddress.address.country")
    )
)

export const isRequiredIfIntlAndSelectGenerateInvoice = isRequiredIf(
    values =>
        (isInternationalShipment(
            get(values, "origin.shippingAddress.address.country"),
            get(values, "destination.shippingAddress.address.country")
        ) ||
            isUSDomesticOffshoreShipmentWithItemCustoms(
                get(values, "origin.shippingAddress.address.country"),
                get(values, "destination.shippingAddress.address.country"),
                get(values, "origin.shippingAddress.address.state"),
                get(values, "destination.shippingAddress.address.state")
            )) &&
        get(values, "requiresCommercialInvoice")
)

const isRequiredIfFreightClassConditions = isRequiredIf(values => {
    if (get(values, "selectedLocation.usDimWeight", true)) {
        return false
    }
    return !(
        get(values, "origin.shippingAddress.address.country") === "MX" &&
        get(values, "destination.shippingAddress.address.country") === "MX"
    )
})

const isRequiredIfMXRate = isRequiredIf(
    values =>
        get(values, "origin.shippingAddress.address.country") === "MX" ||
        get(values, "destination.shippingAddress.address.country") === "MX"
)

const isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter = isRequiredIf(
    values =>
        (isInternationalShipment(
            get(values, "origin.shippingAddress.address.country"),
            get(values, "destination.shippingAddress.address.country")
        ) ||
            isUSDomesticOffshoreShipmentWithItemCustoms(
                get(values, "origin.shippingAddress.address.country"),
                get(values, "destination.shippingAddress.address.country"),
                get(values, "origin.shippingAddress.address.state"),
                get(values, "destination.shippingAddress.address.state")
            )) &&
        get(values, "requiresCommercialInvoice") &&
        !isConsigneeImporter(
            get(values, "commercialInvoice.importerIsNotConsignee", false)
        )
)

const isRequiredIfIntlAndSelectGenerateInvoiceAndNaftaDeclaration = isRequiredIf(
    values =>
        (isInternationalShipment(
            get(values, "origin.shippingAddress.address.country"),
            get(values, "destination.shippingAddress.address.country")
        ) ||
            isUSDomesticOffshoreShipmentWithItemCustoms(
                get(values, "origin.shippingAddress.address.country"),
                get(values, "destination.shippingAddress.address.country"),
                get(values, "origin.shippingAddress.address.state"),
                get(values, "destination.shippingAddress.address.state")
            )) &&
        get(values, "requiresCommercialInvoice") &&
        requiredNaftaDeclaration(
            get(values, "commercialInvoice.naftaDeclaration", false)
        )
)

const isRequiredIfStreet1MissingImporterCommercial = isRequiredIf(
    values =>
        !isConsigneeImporter(
            get(values, "commercialInvoice.importerIsNotConsignee", false)
        ) && !get(values, "commercialInvoice.importerOfRecord.address.street1")
)

const isRequiredIfStreet2MissingImporterCommercial = isRequiredIf(
    values =>
        !isConsigneeImporter(
            get(values, "commercialInvoice.importerIsNotConsignee", false)
        ) && !get(values, "commercialInvoice.importerOfRecord.address.street2")
)

const isRequiredIfBrokerAddressIsFilledOut = (prefix = "") =>
    isRequiredIf(values => {
        return (
            get(values, `${prefix}.address.street1`) ||
            get(values, `${prefix}.address.street2`) ||
            get(values, `${prefix}.address.country`) ||
            get(values, `${prefix}.address.city`) ||
            get(values, `${prefix}.address.postalCode`) ||
            get(values, `${prefix}.address.state`)
        )
    })

const isRequiredIfBrokerAddressAndStreet2Missing = (prefix = "") =>
    isRequiredIf(values => {
        return (
            (get(values, `${prefix}.address.street1`) ||
                get(values, `${prefix}.address.street2`) ||
                get(values, `${prefix}.address.country`) ||
                get(values, `${prefix}.address.city`) ||
                get(values, `${prefix}.address.postalCode`) ||
                get(values, `${prefix}.address.state`)) &&
            !get(
                values,
                prefix ? `${prefix}.address.street2` : "address.street2"
            )
        )
    })

const widthValidator = () => (field, fieldValue, formValues) =>
    formValues?.preferredSystemOfMeasurement === "METRIC"
        ? isSmallerThanOrEqualTo(fieldValue, 236, field)
        : isSmallerThanOrEqualTo(fieldValue, 93, field)

const heightValidator = () => (field, fieldValue, formValues) =>
    formValues?.preferredSystemOfMeasurement === "METRIC"
        ? isSmallerThanOrEqualTo(fieldValue, 271, field)
        : isSmallerThanOrEqualTo(fieldValue, 107, field)

const overLengthValidator = () => (field, fieldValue, formValues) =>
    // Shipment Restriction clean up needed below
    formValues?.selectedLocation?.shipmentRestrictions
        ?.restrictOverLengthShipments ||
    formValues?.selectedLocation?.restrictOverLengthShipments
        ? formValues?.preferredSystemOfMeasurement === "METRIC"
            ? isSmallerThan(fieldValue, 243, field)
            : isSmallerThan(fieldValue, 96, field)
        : undefined

const extremeLengthValidator = () => (field, fieldValue, formValues) =>
    // Shipment Restriction clean up needed below
    formValues?.selectedLocation?.shipmentRestrictions
        ?.restrictExtremeLengthShipments ||
    formValues?.selectedLocation?.restrictExtremeLengthShipments
        ? formValues?.preferredSystemOfMeasurement === "METRIC"
            ? isSmallerThan(fieldValue, 365, field)
            : isSmallerThan(fieldValue, 144, field)
        : undefined

const isSmallerThanOrEqualTo = (value, max, field) => {
    if (value !== null && Number(value) <= max) {
        return false
    } else {
        return (
            <FormattedMessage
                id="generalTerms.validation__isSmallerThan"
                defaultMessage="{field} must be smaller than {max}"
                values={{ field: field?.field?.defaultMessage, max: max }}
            />
        )
    }
}
const isSmallerThan = (value, max, field) => {
    if (value !== null && Number(value) < max) {
        return false
    } else {
        return (
            <FormattedMessage
                id="generalTerms.validation__isSmallerThan"
                defaultMessage="{field} must be smaller than {max}"
                values={{ field: field?.field?.defaultMessage, max: max }}
            />
        )
    }
}

const isRequiredIfBrokerAddressAndStreet1Missing = (prefix = "") =>
    isRequiredIf(values => {
        return (
            (get(values, `${prefix}.address.street1`) ||
                get(values, `${prefix}.address.street2`) ||
                get(values, `${prefix}.address.country`) ||
                get(values, `${prefix}.address.city`) ||
                get(values, `${prefix}.address.postalCode`) ||
                get(values, `${prefix}.address.state`)) &&
            !get(
                values,
                prefix ? `${prefix}.address.street1` : "address.street1"
            )
        )
    })

export const isRequiredIfFreightDirect = isRequiredIf(
    values =>
        get(values, "isFreightDirectReturns") ||
        get(values, "isFreightDirect") ||
        get(values, "selectedLocation.locationType" === "FEDEX_DIRECT")
)

export const declaredValueOptionsValidation = () => ({
    declaredValue: composeValidators(
        isDecimalNumeric,
        isGreaterThan(0)
    )({
        field: {
            id: "generalTerms__declaredValue",
            defaultMessage: "Declared Value",
        },
    }),

    coverageAmount: composeValidators(
        isDecimalNumeric,
        isGreaterThan(0),
        isRequired
    )({
        field: {
            id: "generalTerms__excessLiability",
            defaultMessage: "Excess Liability",
        },
    }),
})

export const customsCommercialInvoiceValidation = () => ({
    termsOfSale: isRequiredIfIntlAndSelectGenerateInvoice({
        field: {
            id: "orderDetails.support__documentation__termsOfSale",
            defaultMessage: "Terms Of Sale",
        },
    }),
    dutiesPaidBy: isRequiredIfIntlAndSelectGenerateInvoice({
        field: {
            id:
                "orderDetails.support__documentation__select__duties_payable_by",
            defaultMessage: "Duties Payable By",
        },
    }),
    naftaLowValueRole: isRequiredIfIntlAndSelectGenerateInvoiceAndNaftaDeclaration(
        {
            field: {
                id: "orderDetails.support__documentation__naftaLowValueRole",
                defaultMessage: "USMCA Status",
            },
        }
    ),
    importerOfRecord: {
        taxIdentification: {
            type: isRequiredIf(values =>
                get(
                    values,
                    "commercialInvoice.importerOfRecord.taxIdentification.number"
                )
            )({
                field: {
                    id:
                        "orderDetails.support__documentation__importer_select__tax__type",
                    defaultMessage: "Select Importer Tax Identification Type",
                },
            }),
            number: isRequiredIf(values =>
                get(
                    values,
                    "commercialInvoice.importerOfRecord.taxIdentification.type"
                )
            )({
                field: {
                    id:
                        "orderDetails.support__documentation__importer__tax__identification__number",
                    defaultMessage: " Importer Tax Identification Number",
                },
            }),
        },
        name: isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter({
            field: {
                id:
                    "orderDetails.support__documentation__importer__companyName",
                defaultMessage: " Importer Company Name",
            },
        }),
        address: {
            street1: isRequiredIfStreet2MissingImporterCommercial({
                field: {
                    id:
                        "orderDetails.support__documentation__importer_address1",
                    defaultMessage: "Importer Address 1",
                },
            }),
            street2: isRequiredIfStreet1MissingImporterCommercial({
                field: {
                    id:
                        "orderDetails.support__documentation__importer_address2",
                    defaultMessage: "Importer Address 2",
                },
            }),
            postalCode: isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter(
                {
                    field: {
                        id:
                            "orderDetails.support__documentation__importer_zipPostalCode",
                        defaultMessage: "Importer Zip/Postal Code",
                    },
                }
            ),
            city: isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter(
                {
                    field: {
                        id:
                            "orderDetails.support__documentation__importer__city",
                        defaultMessage: "Importer City",
                    },
                }
            ),
            country: isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter(
                {
                    field: {
                        id:
                            "orderDetails.support__documentation__importer__country",
                        defaultMessage: "Importer Country",
                    },
                }
            ),
            state: isRequiredIfIntlAndSelectGenerateInvoiceAndCosigneeIsNotImporter(
                {
                    field: {
                        id:
                            "orderDetails.support__documentation__importer__state",
                        defaultMessage: "Importer State/Province",
                    },
                }
            ),
        },
    },
})

export const customsBrokerValidationV2 = (countryField, prefix) => ({
    selectBroker: isRequiredIfIntlAndNoBrokerName(prefix)({
        field: {
            id: "addBroker__selectBroker",
            defaultMessage: "Select Existing Broker",
        },
    }),
    brokerName: isRequiredBroker(prefix)({
        field: {
            id: "generalTerms__brokerName",
            defaultMessage: "Broker Name",
        },
    }),
    phone: {
        phone_number: composeValidators(
            isRequiredBroker(prefix),
            countryPhoneValidator(countryField)
        )({
            field: {
                id: "generalTerms__phoneNumber",
                defaultMessage: "Phone Number",
            },
        }),
        extension: isNumeric({
            field: {
                id: "generalTerms__extension",
                defaultMessage: "Extension",
            },
        }),
    },
    email: {
        email_address: composeValidators(
            isRequiredBroker(prefix),
            emailValidator
        )({
            field: {
                id: "generalTerms__emailAddress",
                defaultMessage: "Email Address",
            },
        }),
    },
    address: {
        street1: isRequiredIfBrokerAddressAndStreet2Missing(prefix)({
            field: messages.address1OrAddress2Field,
        }),
        street2: isRequiredIfBrokerAddressAndStreet1Missing(prefix)({
            field: messages.address1OrAddress2Field,
        }),
        city: isRequiredIfBrokerAddressIsFilledOut(prefix)({
            field: messages.cityField,
        }),
        state: isRequiredIfBrokerAddressIsFilledOut(prefix)({
            field: messages.stateField,
        }),
        postalCode: isRequiredIfBrokerAddressIsFilledOut(prefix)({
            field: messages.postalCodeField,
        }),
        country: isRequiredIfBrokerAddressIsFilledOut(prefix)({
            field: messages.countryField,
        }),
    },
})

export const customsBrokerValidation = countryField => ({
    name: isRequiredIfIntl({
        field: {
            id: "generalTerms__companyName",
            defaultMessage: "Company Name",
        },
    }),
    contact: {
        phone: {
            phone_number: composeValidators(
                isRequiredIfIntl,
                countryPhoneValidator(countryField)
            )({
                field: {
                    id: "generalTerms__phoneNumber",
                    defaultMessage: "Phone Number",
                },
            }),
            extension: isNumeric({
                field: {
                    id: "generalTerms__extension",
                    defaultMessage: "Extension",
                },
            }),
        },
        email: {
            email_address: composeValidators(
                isRequiredIfIntl,
                emailValidator
            )({
                field: {
                    id: "generalTerms__emailAddress",
                    defaultMessage: "Email Address",
                },
            }),
        },
    },
    address: {
        street1: isRequiredIfBrokerAddressAndStreet2Missing("")({
            field: messages.address1OrAddress2Field,
        }),
        street2: isRequiredIfBrokerAddressAndStreet1Missing("")({
            field: messages.address1OrAddress2Field,
        }),
        city: isRequiredIfBrokerAddressIsFilledOut("")({
            field: messages.cityField,
        }),
        state: isRequiredIfBrokerAddressIsFilledOut("")({
            field: messages.stateField,
        }),
        postalCode: isRequiredIfBrokerAddressIsFilledOut("")({
            field: messages.postalCodeField,
        }),
        country: isRequiredIfBrokerAddressIsFilledOut("")({
            field: messages.countryField,
        }),
    },
})

export const pickupValidator = countryField => ({
    readyTime: isRequiredIfPickedUp({
        field: messages.pickupReadyField,
    }),
    closeTime: composeValidators(
        isRequiredIfPickedUp,
        pickupWindowValidator
    )({
        field: messages.pickupCloseField,
    }),
    pickUpWindow: isRequiredIfFreightDirectReturnsStandardOrBasicwAppt({
        field: messages.pickUpWindow,
    }),
    pickupContact: {
        name: composeValidators(
            isRequiredIfPickedUp,
            hasLengthGreaterThan(2)
        )({ field: messages.pickupNameField }),
        phone: {
            phone_number: composeValidators(
                isRequiredIfPickedUp,
                countryPhoneValidator(countryField)
            )({
                field: messages.pickupPhoneField,
            }),
            extension: isNumeric({
                field: messages.pickupExtensionField,
            }),
        },
        email: {
            email_address: composeValidators(
                isRequiredIfPickedUp,
                emailValidator
            )({
                field: messages.pickupEmailField,
            }),
        },
    },
})

export const handlingUnitsValidator = {
    "handlingUnits[].items[].nmfcCode": nmfcValidator,
    "handlingUnits[].items[].nmfcSubclass": nmfcSubcodeValidator,
    "handlingUnits[].items[].countryOfManufacture": isRequiredIfNonDomesticOrOffshoreWithItemCustoms(
        {
            field: {
                id: "items__countryOfManufacture",
                defaultMessage: "Country of Manufacture",
            },
        }
    ),
    "handlingUnits[].items[].unitPrice": isRequiredIfNonDomesticOrOffshoreWithItemCustoms(
        {
            field: {
                id: "items_unitPriceNoCurrency",
                defaultMessage: "Unit Price",
            },
        }
    ),
    "handlingUnits[].items[].unit": isRequiredIfIntlAndSelectGenerateInvoice({
        field: {
            id: "orderDetails.support__documentation__No__of__units",
            defaultMessage: " {itemName} No. of units",
            values: {
                itemName: "",
            },
        },
    }),
    "handlingUnits[].items[].unitOfMeasure": isRequiredIfIntlAndSelectGenerateInvoice(
        {
            field: {
                id: "orderDetails.support__documentation__unit__of__measure",
                defaultMessage: " {itemName} Unit of measure",
                values: {
                    itemName: "",
                },
            },
        }
    ),
    "handlingUnits[].items": hasLengthGreaterThan(0)({
        field: { id: "items__item", defaultMessage: "Item" },
    }),
    "handlingUnits[].items[].weight": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__weight", defaultMessage: "Weight" } }),
    "handlingUnits[].count": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__huCount", defaultMessage: "H/U Count" } }),
    "handlingUnits[].totalHUWeight": composeValidators(
        isRequiredIfIndividualHUWeight,
        isNumeric,
        isGreaterThan(0)
    )({
        field: {
            id: "items__totalHUWeight",
            defaultMessage: "Individual H/U Weight",
        },
    }),
    "handlingUnits[].totalWeight": composeValidators(
        isRequiredIfNotIndividualHUWeight,
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__totalWeight", defaultMessage: "Total Weight" } }),
    "handlingUnits[].items[].pieces": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__pieces", defaultMessage: "Pieces" } }),
    "handlingUnits[].items[].freightDirectPieces[].count": composeValidators(
        isRequiredIfFreightDirect,
        isNumeric,
        isGreaterThan(0)
    )({ field: { id: "items__count", defaultMessage: "Count" } }),
    "handlingUnits[].items[].freightDirectPieces[].weight": composeValidators(
        isRequiredIfFreightDirect,
        isGreaterThan(0),
        isNumeric
    )({ field: { id: "items__weight", defaultMessage: "Weight" } }),
    "handlingUnits[].packageType": composeValidators(
        isRequired,
        packageTypeValidator
    )({
        field: {
            id: "items__packageType",
            defaultMessage: "Package Type",
        },
    }),
    "handlingUnits[].items[].description": composeValidators(
        isRequired,
        hasLengthLessThan(201)
    )({
        field: {
            id: "items__description",
            defaultMessage: "Description",
        },
    }),
    "handlingUnits[].items[].freightClass": composeValidators(
        isRequiredIfFreightClassConditions,
        isOneOfSilent(freightClassValues)
    )({ field: { id: "items__class", defaultMessage: "Class" } }),
    "handlingUnits[].width": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0),
        widthValidator()
    )({ field: { id: "items__width", defaultMessage: "Width" } }),
    "handlingUnits[].height": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0),
        overLengthValidator(),
        heightValidator()
    )({ field: { id: "items__height", defaultMessage: "Height" } }),
    "handlingUnits[].length": composeValidators(
        isRequired,
        isNumeric,
        isGreaterThan(0),
        overLengthValidator(),
        extremeLengthValidator()
    )({ field: { id: "items__length", defaultMessage: "Length" } }),
    "handlingUnits[].items[].hazMatUnNumber": composeValidators(
        hasHazMatIdPrefix(),
        hasLength(6)
    )({
        field: {
            id: "items__unNumber__error",
            defaultMessage:
                "Identification Numbers require a prefix of ‘UN or NA or ID’ before the 4 numeral code.",
        },
    }),
    "handlingUnits[].items[].hazMatPkgGrp": composeValidators(
        isOneOf(pkgGrpValues)
    )({ field: { id: "items__pkgGrp", defaultMessage: "Pkg Grp" } }),
    "handlingUnits[].items[].hazMatClass": composeValidators(
        isOneOf(hazClassValues)
    )({ field: { id: "items__hazClass", defaultMessage: "Haz Class" } }),
    "handlingUnits[].items[].hazMatContainer": composeValidators(
        isOneOf(hazContainersValues)
    )({
        field: {
            id: "items__containerType",
            defaultMessage: "Container Type",
        },
    }),
    "handlingUnits[].items[].packagingCode": isRequiredIfMXRate({
        field: { id: "items__packagingCode", defaultMessage: "Packaging Code" },
    }),
    "handlingUnits[].items[].commodityCode": isRequiredIfMXRate({
        field: { id: "items__commodityCode", defaultMessage: "Commodity Code" },
    }),
}

export const bookShipmentFormValidation = (
    values,
    props,
    featureTransition = false
) => {
    const isToOrFromMexico =
        values?.destination?.shippingAddress?.address?.country &&
        values?.origin?.shippingAddress?.address?.country
            ? isToOrFromMexicoShipment(
                  values.origin.shippingAddress.address.country,
                  values.destination.shippingAddress.address.country
              )
            : false
    const mainValidation = combineValidators({
        billToLocation: isRequired({
            field: {
                id: "getRates.form__billToLocation",
                defaultMessage: "Bill to Location",
            },
        }),
        pickupDate: composeValidators(
            isRequired,
            pickUpDateValidator("isFreightDirectReturns")
        )({
            field: {
                id: "getRates.form__shipDate",
                defaultMessage: "Ship Date",
            },
        }),
        paymentType: isRequired({
            field: {
                id: "bookShipment.basicInfo__paymentTerms",
                defaultMessage: "Payment Terms",
            },
        }),
        mode: isRequired({
            field: {
                id: "bookShipment.basicInfo__mode",
                defaultMessage: "Mode",
            },
        }),
        origin: {
            shippingAddress: addressValidator("origin.shippingAddress"),
            taxIdentification: {
                type: composeValidators(
                    isRequiredIf(
                        values => values?.origin?.taxIdentification?.number
                    )
                )({
                    field: {
                        id:
                            "orderDetails.support__documentation__exporter__select__tax__type",
                        defaultMessage:
                            "Select  Exporter Tax Identification Type",
                    },
                }),
                number: isToOrFromMexico
                    ? composeValidators(
                          isAlphaNumericOnly,
                          isRequired,
                          hasLengthGreaterThan(11),
                          hasLengthLessThan(14)
                      )({
                          field: "Shipper Tax ID",
                      })
                    : composeValidators(
                          isRequiredIf(
                              values => values?.origin?.taxIdentification?.type
                          )
                      )({
                          field: {
                              id:
                                  "orderDetails.support__documentation__exporter__tax__identification__number",
                              defaultMessage:
                                  " Exporter Tax Identification Number",
                          },
                      }),
            },
            contact: contactValidator("origin.shippingAddress.address.country"),
            ...(featureTransition
                ? null
                : pickupValidator("origin.shippingAddress.address.country")),
        },
        destination: {
            shippingAddress: addressValidator("destination.shippingAddress"),
            contact: contactValidator(
                "destination.shippingAddress.address.country"
            ),
            taxIdentification: {
                type: composeValidators(
                    isRequiredIf(
                        values => values?.destination?.taxIdentification?.number
                    )
                )({
                    field: {
                        id:
                            "orderDetails.support__documentation__consignee__select__tax__type",
                        defaultMessage:
                            "Select  Consignee  Tax Identification Type",
                    },
                }),
                number: isToOrFromMexico
                    ? composeValidators(
                          isAlphaNumericOnly,
                          isRequired,
                          hasLengthGreaterThan(11),
                          hasLengthLessThan(14)
                      )({
                          field: "Consignee Tax ID",
                      })
                    : composeValidators(
                          isRequiredIf(
                              values =>
                                  values?.destination?.taxIdentification?.type
                          )
                      )({
                          field: {
                              id:
                                  "orderDetails.support__documentation__consignee__tax__identification__number",
                              defaultMessage:
                                  " Consignee Tax Identification Number",
                          },
                      }),
            },
        },
        isFedexFileEEI: composeValidators(
            isRequiredIf(
                values =>
                    (values.destination?.shippingAddress?.address?.state ===
                        "PR" ||
                        values.origin?.shippingAddress?.address?.state ===
                            "PR") &&
                    values.mode !== "thirdParty"
            )
        )({
            field: {
                id: "destination.EEI",
                defaultMessage: "Electronic export information choice",
            },
        }),
        paymentAccount: {
            name: composeValidators(
                isRequiredIf(values => values?.paymentType === "THIRD_PARTY")
            )({
                field: {
                    id: "generalTerms__companyName",
                    defaultMessage: "Company Name",
                },
            }),
            accountNumber: composeValidators(
                isNumeric,
                hasLengthLessThan(10)
            )({
                field: {
                    id: "addLocation.billing__fedExThirdPartyAccountNumber",
                    defaultMessage: "FedEx Third Party Account #:",
                },
            }),
            address: {
                street1: isRequiredIf(
                    values =>
                        values?.paymentType === "THIRD_PARTY" &&
                        !values?.paymentAccount?.address?.street2
                )({
                    field: {
                        id: "generalTerms__address1",
                        defaultMessage: "Address 1",
                    },
                }),
                street2: isRequiredIf(
                    values =>
                        values?.paymentType === "THIRD_PARTY" &&
                        !values?.paymentAccount?.address?.street1
                )({
                    field: {
                        id: "generalTerms__address2",
                        defaultMessage: "Address 2",
                    },
                }),
                city: composeValidators(
                    isRequiredIf(
                        values => values?.paymentType === "THIRD_PARTY"
                    )
                )({
                    field: {
                        id: "generalTerms__city",
                        defaultMessage: "City",
                    },
                }),
                state: composeValidators(
                    isRequiredIf(
                        values => values?.paymentType === "THIRD_PARTY"
                    )
                )({
                    field: {
                        id: "generalTerms__state",
                        defaultMessage: "State",
                    },
                }),
                postalCode: composeValidators(
                    isRequiredIf(
                        values => values?.paymentType === "THIRD_PARTY"
                    )
                )({
                    field: {
                        id: "generalTerms__zipPostalCode",
                        defaultMessage: "Zip/Postal Code",
                    },
                }),
                country: composeValidators(
                    isRequiredIf(
                        values => values?.paymentType === "THIRD_PARTY"
                    )
                )({
                    field: {
                        id: "generalTerms__country",
                        defaultMessage: "Country",
                    },
                }),
            },
            contact: {
                name: composeValidators(
                    isRequiredIf(
                        values => values?.paymentType === "THIRD_PARTY"
                    ),
                    hasLengthGreaterThan(2)
                )({
                    field: {
                        id: "generalTerms__contactName",
                        defaultMessage: "Contact Name",
                    },
                }),
                phone: {
                    phone_number: composeValidators(
                        isRequiredIf(
                            values => values?.paymentType === "THIRD_PARTY"
                        ),
                        countryPhoneValidator("paymentAccount.address.country")
                    )({
                        field: {
                            id: "generalTerms__contactPhone",
                            defaultMessage: "Contact Phone",
                        },
                    }),
                    extensionField: isNumeric({
                        field: {
                            id: "generalTerms__extension",
                            defaultMessage: "Extension",
                        },
                    }),
                },
                email: {
                    email_address: composeValidators(emailValidator)({
                        field: {
                            id: "generalTerms__contactEmail",
                            defaultMessage: "Contact Email",
                        },
                    }),
                },
            },
        },
        exportCustomsBrokerProfile: customsBrokerValidationV2(
            "origin.shippingAddress.address.country",
            "exportCustomsBrokerProfile"
        ),
        importCustomsBrokerProfile: customsBrokerValidationV2(
            "destination.shippingAddress.address.country",
            "importCustomsBrokerProfile"
        ),
        commercialInvoice: customsCommercialInvoiceValidation(),
        certificateOfOrigin: customsCOOValidation(),
        usmca: usmcaFormValidation,
        purposeOfShipment: isRequiredIfIntlAndSelectGenerateInvoice({
            field: {
                id: "orderDetails.support__documentation__select_purpose",
                defaultMessage: "Select Purpose Of Shipment",
            },
        }),
        declaredValueOptions: declaredValueOptionsValidation(),
        identifiers: {
            aesCitationNumber: aesNumberValidator({
                field: {
                    id: "orderDetails.label__AES",
                    defaultMessage: "AES Citation Number",
                },
            }),
            bolNumber: composeValidators(isRequired)({
                field: {
                    id: "orderDetails.label__BOL",
                    defaultMessage: "Bill of Lading ID",
                },
            }),
            orderNumber: composeValidators(customReferencesValidation("po"))({
                field: {
                    id: "generalTerms__PO",
                    defaultMessage: "PO",
                },
            }),
            referenceNumber: composeValidators(
                customReferencesValidation("refId")
            )({
                field: {
                    id: "generalTerms__referenceId",
                    defaultMessage: "Reference ID",
                },
            }),
        },
        hazmatEmergency: hazmatValidator(
            "origin.shippingAddress.address.country"
        ),
        totalLinearFeet: isRequired({
            field: {
                id: "getRates.form__estLinearFeet",
                defaultMessage: "Est. Linear Feet",
            },
        }),
        ...handlingUnitsValidator,
        documentCategory: isRequired({
            field: {
                id: "documents.upload__selectFileCategory",
                defaultMessage: "File Category",
            },
        }),
        customFilename: isRequired({
            field: {
                id: "documents.upload__userFileName",
                defaultMessage: "Custom File Name",
            },
        }),
    })(values, props)
    return merge({}, mainValidation, itemPropertiesFormValidator(values))
}
