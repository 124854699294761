import React from "react"
import { Provider } from "react-redux"
import ReactDOM from "react-dom"
import "react-select/dist/react-select.css"
import "react-table/react-table.css"
import { PersistGate } from "redux-persist/integration/react"
import {
    createGenerateClassName,
    StylesProvider,
} from "@material-ui/core/styles"
import { configureStore } from "./configure-store"
import { App } from "./app"
import { initPurifier } from "./misc"
import "./main.css"
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk"
import AccessorialsProvider from "./context/providers/AccessorialsProvider"
import { OuterErrorBoundary } from "./components/errors/OuterErrorBoundary"

const initApp = async () => {
    const LDProvider = await asyncWithLDProvider({
        clientSideID: window._env_.REACT_APP_LAUNCHDARKLY_CLIENT_ID,
        context: {
            kind: "user",
            key: "unauthenticatedUser",
        },
    })

    const generateClassName = createGenerateClassName({
        seed: "muijss",
    })

    initPurifier()

    const { store, persistor } = configureStore()
    window.store = store

    ReactDOM.render(
        <LDProvider>
            <OuterErrorBoundary>
                <Provider store={store}>
                    <PersistGate loading={null} persistor={persistor}>
                        <StylesProvider generateClassName={generateClassName}>
                            <AccessorialsProvider>
                                <App store={store} />
                            </AccessorialsProvider>
                        </StylesProvider>
                    </PersistGate>
                </Provider>
            </OuterErrorBoundary>
        </LDProvider>,
        document.getElementById("root")
    )
}

initApp()
