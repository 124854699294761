import React from "react"
import PropTypes from "prop-types"
import { withStyles } from "@material-ui/core/styles"
import CircularProgress from "@material-ui/core/CircularProgress"

const styles = theme => ({
    progress: {
        position: "absolute",
        margin: "auto",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
    },
})

function GlobalSpinner(props) {
    const { classes, size, color } = props

    return (
        <CircularProgress
            className={classes.progress}
            size={size || 50}
            color={color || "primary"}
        />
    )
}

GlobalSpinner.propTypes = {
    classes: PropTypes.object.isRequired,
    size: PropTypes.number,
    color: PropTypes.string,
}

export default withStyles(styles)(GlobalSpinner)
