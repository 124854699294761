import { React, useState, useEffect, Fragment } from "react"
import { makeStyles } from "@material-ui/core/styles"
import { requestBillToLocation } from "./requests"
import Header from "./header"
import AddCarrier from "./addCarrier/index"
import EditCarrier from "./editCarrier/index"
import { loggedIn } from "../../actions/authorization"
import { useParams } from "react-router-dom/cjs/react-router-dom.min"
import { useSnackbarContext } from "../../context/providers/snackbarProvider"

export const CarrierOnboard = props => {
    const { match } = props
    const [billToLocation, setBillToLocation] = useState(null) // State for billToLocation
    const [readyToLoad, setReadyToLoad] = useState(false) // Loading state
    const { carrierId } = useParams()
    const { openSnackbar } = useSnackbarContext()

    useEffect(() => {
        if (!loggedIn()) {
            // console.log(`I was running`)
            // history.push("/")
            // window.location.reload()
            // return
            // return <Redirect to={{ pathname: "/user/signup" }} />
            window.location.href = "/"
        }
        const fetchBillToLocation = async () => {
            try {
                const location = await requestBillToLocation()
                setBillToLocation(location)
            } catch (error) {
                console.error("Error fetching billToLocation:", error)
                setBillToLocation(null) // Set to null in case of an error
            } finally {
                setReadyToLoad(true)
            }
        }
        fetchBillToLocation()
    }, [])
    return (
        <Fragment>
            <Header
                {...props}
                billToLocation={billToLocation}
                mode={match.params.mode}
            />
            {match.params.mode == "add" && !carrierId ? (
                <AddCarrier
                    {...props}
                    billToLocation={billToLocation}
                    openSnackbar={openSnackbar}
                />
            ) : (
                <EditCarrier
                    {...props}
                    billToLocation={billToLocation}
                    openSnackbar={openSnackbar}
                />
            )}
        </Fragment>
    )
}
