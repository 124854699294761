import { parseNumber, isValidNumber } from "libphonenumber-js"
import moment from "moment-timezone"
import { isObject, isString, every, map, get, includes } from "lodash"
import { composeValidators, createValidator } from "revalidate"
import { defineMessages } from "react-intl"
import {
    originCloseTimePast,
    originCloseTooEarly,
    shipmentExtremeLength,
    fieldOverLimit,
    hazMatUNLength,
    phoneInvalid,
    maximumWeightExceededVLTL,
    maximumWeightExceededLTL,
    shipmentExceedLength,
    numberGreaterThanZero,
    fieldRequired,
    fieldMustBeNumber,
    textLength,
    readyAndCloseTimeApartValue,
    shipDateInvalid,
    customReferenceInvalid,
} from "../messages/validation-constants"
import { requestPostalCode } from "./postal-code"
import { isFedExCarrier, supportedLanguagesCountryMap } from "../misc"
import { isMoment } from "moment"
import {
    freightBoxPackageTypeValues,
    packageTypeValues,
} from "../components/item/constants"
import { containsCombinedOtherRatesAndFedexOceanRates } from "../components/util/carrierLogoUtils"

export const FIELD_VALIDATION = "FIELD_VALIDATION"
export const SCROLLED_TO_VALIDATION = "SCROLLED_TO_VALIDATION"
export const RESET_VALIDATION_SCROLL = "RESET_VALIDATION_SCROLL"

const messages = defineMessages({
    pastDateValidator: {
        id: "generalTerms.validation__pastDateValidator",
        defaultMessage: "Ship date is in the past in the origin timezone",
    },
    handlingUnits: {
        id: "generalTerms.validation__handlingUnits",
        defaultMessage: "Handling Units",
    },
    hazClass: {
        id: "items__hazClass",
        defaultMessage: "Haz Class",
    },
    packageGroup: {
        id: "items__packageGroup",
        defaultMessage: "Package Group",
    },
    unitPriceLabel: {
        id: "items_unitPriceNoCurrency",
        defaultMessage: "Unit Price",
    },
    invalidPostalCode: {
        id: "generalTerms.validation__invalidPostalCode",
        defaultMessage: "Invalid Postal Code",
    },
    numericShorthand: {
        id: "generalTerms.validation__numeric",
        defaultMessage: "Must be numeric",
    },
    requiredShorthand: {
        id: "generalTerms.validation__required",
        defaultMessage: "Required",
    },
    width: {
        id: "generalTerms.valdation__width",
        defaultMessage: "Width",
    },
    length: {
        id: "generalTerms.valdation__length",
        defaultMessage: "Length",
    },
    height: {
        id: "generalTerms.valdation__height",
        defaultMessage: "Height",
    },
    address1OrAddress2: {
        id: "generalTerms__address1OrAddress2",
        defaultMessage: "Address 1 or Address 2",
    },
    error: {
        id: "generalTerms__error",
        defaultMessage: "Error",
    },
    item: {
        id: "items__item",
        defaultMessage: "Item",
    },
    isRequired: {
        id: "generalTerms.validation__isRequired",
        defaultMessage: "{field} is required",
    },
    mustBeWithinOneYear: {
        id: "generalTerms.validation__mustBeWithinOneYear",
        defaultMessage: "{field} must be within one year",
    },
    validAES: {
        id: "generalTerms.validation__AES",
        defaultMessage: "AES format: XYYYYMMDDNNNNNN",
    },
    isNumeric: {
        id: "generalTerms.validation__isNumeric",
        defaultMessage: "{field} must be numeric",
    },
    hasLengthLessThan: {
        id: "generalTerms.validation__hasLengthLessThan",
        defaultMessage: "{field} cannot be longer than {max} characters",
    },
    phoneValidator: {
        id: "generalTerms.validation__phoneValidator",
        defaultMessage: "{field} is not a phone number",
    },
    emailValidator: {
        id: "generalTerms.validation__emailValidator",
        defaultMessage: "Invalid email address",
    },
    acceptValidator: {
        id: "generalTerms.validation__acceptValidator",
        defaultMessage: "You must accept {field}",
    },
    isOneOfSilent: {
        id: "generalTerms.validation__isOneOfSilent",
        defaultMessage: "{field} has invalid value",
    },
    isOneOf: {
        id: "generalTerms.validation__isOneOf",
        defaultMessage: "{field} has invalid value",
    },
    hasLength: {
        id: "generalTerms.validation__hasLength",
        defaultMessage: "{field} must be {length} characters long",
    },
    hasLengthGreaterThan: {
        id: "generalTerms.validation__hasLengthGreaterThan",
        defaultMessage: "{field} must be longer than {min} characters long",
    },
    isGreaterThan: {
        id: "generalTerms.validation__isGreaterThan",
        defaultMessage: "{field} must be greater than {min}",
    },
    isSmallerThan: {
        id: "generalTerms.validation__isSmallerThan",
        defaultMessage: "{field} must be smaller than {max}",
    },
    totalWeightValidator: {
        id: "generalTerms.validation__totalWeightValidator",
        defaultMessage: "{field} is over limit",
    },
    hasValidCity: {
        id: "generalTerms.validation__hasValidCity",
        defaultMessage: "A city must be selected for {field}",
    },
    nmfcCodeField: {
        id: "generalTerms__nmfcCode",
        defaultMessage: "NMFC Code",
    },
    nmfcSubcodeField: {
        id: "generalTerms__nmfcSubcode",
        defaultMessage: "NMFC Subcode",
    },
    hasVagueCommodity: {
        id: "generalTerms__vagueCommodity",
        defaultMessage: "Incomplete item description",
    },
    matchesPattern: {
        id: "generalTerms.validation__isPattern",
        defaultMessage: "{field} has invalid format",
    },
    hasHazMatIdPrefix: {
        id: "items__unNumber__error",
        defaultMessage:
            "Identification Numbers require a prefix of ‘UN or NA or ID’ before the 4 numeral code.",
    },
    hasLengthRestrictedBasedOnCountry: {
        id: "generalTerms.validation__hasLengthRestrictedBasedOnCountry",
        defaultMessage: "{field} is too long for selected country.",
    },
    hazMatContainer: {
        id: "items__containerType",
        defaultMessage: "Container Type",
    },
    freightBoxWeight: {
        id: "items.validation__freightBoxWeight",
        defaultMessage: "Weight per H/U cannot exceed 1200lbs",
    },
    piecesCount: {
        id: "items.validation__piecesCount",
        defaultMessage: "Piece Count Cannot Exceed 9999",
    },
    alphaNumeric: {
        id: "generalTerms.validation__alphaNumeric",
        defaultMessage: "{field}  cannot contain special characters",
    },
})

export const mergeValidations = (...params) => (...validationParams) =>
    params?.reduce(
        (prev, current) => ({ ...prev, ...current(...validationParams) }),
        {}
    )

export const isRequired = createValidator(
    message => value =>
        value == null || value.toString().trim() === "" ? message : undefined,
    field => ({ ...messages.isRequired, values: { field } })
)

export const packageTypeValidator = createValidator(
    message => (value, allValues) => {
        if (value) {
            if (allValues.isFreightBox) {
                if (!freightBoxPackageTypeValues.includes(value)) {
                    return message
                }
            } else {
                if (!packageTypeValues.includes(value)) {
                    return message
                }
            }
        }
        return undefined
    },
    field => ({ ...messages.isOneOfSilent, values: { field } })
)

export const mustBeWithinOneYear = otherFieldName =>
    createValidator(
        message => (value, allValues) => {
            if (!value || !isMoment(value)) return undefined
            const beforeDate = get(allValues, otherFieldName)
            if (
                !isMoment(beforeDate) ||
                beforeDate.diff(value, "years") <= -1
            ) {
                return message
            }
            return undefined
        },
        field => ({ ...messages.mustBeWithinOneYear, values: { field } })
    )

export const aesNumberValidator = createValidator(
    message => value =>
        value == null || /^X\d{14}$/.test(value) ? undefined : message,
    field => ({ ...messages.validAES, values: { field } })
)

export const isRequiredIf = condition =>
    createValidator(
        message => (value, allValues) =>
            condition(allValues) && (value == null || value === "")
                ? message
                : undefined,
        field => ({ ...messages.isRequired, values: { field } })
    )

export const matchesPattern = pattern =>
    createValidator(
        message => value =>
            value && (pattern.test(value) ? undefined : message),
        field => ({ ...messages.matchesPattern, values: { field } })
    )

export const isNumeric = createValidator(
    message => value => (value && !/^\d+$/.test(value) ? message : undefined),
    field => ({ ...messages.isNumeric, values: { field } })
)

export const isDecimalNumeric = createValidator(
    message => value =>
        value && !/^(0|[1-9]\d*)(\.\d+)?$/.test(value) ? message : undefined,
    field => ({ ...messages.isNumeric, values: { field } })
)

export const hasLengthLessThan = max =>
    createValidator(
        message => value =>
            value && value.length >= max ? message : undefined,
        field => ({
            ...messages.hasLengthLessThan,
            values: { field, max: max - 1 },
        })
    )

export const hasLengthRestrictedBasedOnCountry = countryField => {
    return createValidator(
        message => (value, allValues) => {
            const country = get(allValues, countryField)
            if (value) {
                if (country === "US" || country === "MX") {
                    return value.length >= 6 ? message : undefined
                } else if (country === "CA") {
                    return value.length >= 8 ? message : undefined
                }
            }
        },
        field => ({
            ...messages.hasLengthRestrictedBasedOnCountry,
            values: { field },
        })
    )
}

export const vagueCommodityCheck = vagueCommodityNameList =>
    createValidator(
        message => value =>
            value &&
            includes(vagueCommodityNameList, value.toLowerCase().trim())
                ? message
                : undefined,
        field => ({
            ...messages.hasVagueCommodity,
            values: { field },
        })
    )
export const hasLengthGreaterThan = min =>
    createValidator(
        message => value =>
            value && value.length <= min ? message : undefined,
        field => ({
            ...messages.hasLengthGreaterThan,
            values: { field, min: min },
        })
    )

export const itemPropertiesFormValidator = values => {
    const validation = {
        handlingUnits: [],
    }
    get(values, "handlingUnits", []).forEach((handlingUnit, i) => {
        validation.handlingUnits = [...validation.handlingUnits, { items: [] }]
        get(handlingUnit, "items", []).forEach((item, j) => {
            validation.handlingUnits[i].items.push({})
            let totalPiecesHu = 0
            totalPiecesHu += parseInt(item.pieces)
            if (totalPiecesHu > 9999) {
                validation.handlingUnits[i].items[j].pieces = {
                    ...messages.piecesCount,
                }
            }

            if (values?.isFreightBox) {
                if (!handlingUnit?.isIndividualHUWeight) {
                    const weightPerHU =
                        Number(item?.weight) / Number(handlingUnit?.count)
                    if (weightPerHU > 1200) {
                        validation.handlingUnits[i].items[j].weight = {
                            ...messages.freightBoxWeight,
                            values: { field: messages.freightBoxWeight },
                        }
                    }
                } else {
                    if (Number(item?.weight) > 1200) {
                        validation.handlingUnits[i].items[j].weight = {
                            ...messages.freightBoxWeight,
                            values: { field: messages.freightBoxWeight },
                        }
                    }
                }
            }

            if (
                item?.nmfcSubclass !== null &&
                item?.nmfcSubclass !== "" &&
                (item?.nmfcCode === null || item?.nmfcCode === "")
            ) {
                validation.handlingUnits[i].items[j].nmfcCode = {
                    ...messages.isRequired,
                    values: { field: messages.nmfcCodeField },
                }
            }

            if (item?.isHazMat) {
                if (!item?.hazMatUnNumber) {
                    validation.handlingUnits[i].items[j].hazMatUnNumber = {
                        ...messages.isRequired,
                        values: { field: messages.hasHazMatIdPrefix },
                    }
                }
                if (!item?.hazMatPkgGrp) {
                    validation.handlingUnits[i].items[j].hazMatPkgGrp = {
                        ...messages.isRequired,
                        values: { field: messages.packageGroup },
                    }
                }
                if (!item?.hazMatClass) {
                    validation.handlingUnits[i].items[j].hazMatClass = {
                        ...messages.isRequired,
                        values: { field: messages.hazClass },
                    }
                }
                if (!item?.hazMatContainer) {
                    validation.handlingUnits[i].items[j].hazMatContainer = {
                        ...messages.isRequired,
                        values: { field: messages.hazMatContainer },
                    }
                }
            }
        })
    })
    return validation
}

export const nmfcValidator = composeValidators(
    isNumeric,
    hasLengthLessThan(7)
)({ field: messages.nmfcCodeField })

export const nmfcSubcodeValidator = composeValidators(
    isNumeric,
    hasLengthLessThan(3)
)({ field: messages.nmfcSubcodeField })

export const countryPhoneValidator = (
    countryField,
    languageField,
    fallbackCountryCode
) =>
    createValidator(
        message => (fieldValue, formValues) => {
            const country = languageField
                ? supportedLanguagesCountryMap[get(formValues, languageField)]
                : get(formValues, countryField)
            if (
                !fieldValue ||
                isValidPhoneNumber(fieldValue, country ?? fallbackCountryCode)
            ) {
                return undefined
            }
            return message
        },
        phoneInvalid
    )

export const emailValidator = createValidator(
    message => value => (!value || isValidEmail(value) ? undefined : message),
    field => ({ ...messages.emailValidator, values: { field } })
)

export const acceptValidator = createValidator(
    message => value => (value === true ? undefined : message),
    field => ({ ...messages.acceptValidator, values: { field } })
)

export const isOneOfSilent = arr =>
    createValidator(
        message => value =>
            value && !arr.includes(value) ? message : undefined,
        field => ({ ...messages.isOneOfSilent, values: { field } })
    )

export const isOneOf = arr =>
    createValidator(
        message => value =>
            value && !arr.includes(value) ? message : undefined,
        field => ({ ...messages.isOneOf, values: { field } })
    )

export const hasLength = length =>
    createValidator(
        message => value =>
            value && value.length !== length ? message : undefined,
        field => ({ ...messages.hasLength, values: { field, length } })
    )

export const isGreaterThan = min =>
    createValidator(
        message => value =>
            value !== null && Number(value) <= min ? message : undefined,
        field => ({ ...messages.isGreaterThan, values: { field, min } })
    )

export const isSmallerThan = max =>
    createValidator(
        message => value =>
            value !== null && Number(value) >= max ? message : undefined,
        field => ({ ...messages.isSmallerThan, values: { field, max } })
    )

export const totalWeightValidator = createValidator(
    message => (value, form) => {
        const limit = form.volumeLTL ? 44000 : 20000
        if (value !== null && Number(value) <= limit) {
            return message
        }
    },
    field => ({ ...messages.totalWeightValidator, values: { field } })
)

export const hasValidCity = prefix =>
    createValidator(
        message => (value, form) => {
            const {
                [`${prefix}City`]: city,
                [`${prefix}State`]: state,
                [`${prefix}Address`]: address,
                [`${prefix}Contact`]: contact,
            } = form
            if (value && !(address || contact || (city && state))) {
                return message
            }
        },
        field => ({ ...messages.hasValidCity, values: { field } })
    )

export const pastDateValidator = async (values, dispatch) => {
    const { origin, originCountry, pickupDate } = values
    if (!origin || origin.length !== 5 || !pickupDate || !originCountry)
        return undefined
    const { timezone } =
        (await dispatch(
            requestPostalCode(origin, {
                fetchTimezone: true,
                country: originCountry,
            })
        )) || {}
    if (!timezone) return undefined
    const currentOriginDate = moment()
        .tz(timezone)
        .format("YYYY-MM-DD")
    const targetDate = (moment.isMoment(pickupDate)
        ? pickupDate
        : moment.utc(pickupDate)
    ).format("YYYY-MM-DD")
    if (targetDate < currentOriginDate) {
        const errorMessage = {
            pickupDate: messages.pastDateValidator,
        }
        throw errorMessage
    }
}

const itemRequiredFields = Object.freeze({
    weight: "Weight",
    count: "Handling Units",
    packageType: "Packaging Type",
    description: "Description",
})

const nonVolumeItemRequiredFields = Object.freeze({
    ...itemRequiredFields,
    freightClass: "Freight Class",
    width: "Width",
    height: "Height",
    length: "Length",
})

const itemFieldLengths = Object.freeze({
    description: { name: "Description", maxLength: 200 },
})

export const validateRequired = (state, fields, validation = {}) =>
    Object.keys(fields).reduce((prev, key) => {
        if (!state[key]) validation[key] = fieldRequired(fields[key])
        return prev
    }, validation)

export const validateFieldLength = (state, fields, validation = {}) =>
    Object.keys(fields).reduce((prev, key) => {
        const { maxLength, name } = fields[key]
        if (state[key] && state[key].length > maxLength) {
            validation[key] = textLength(name, maxLength)
        }
        return prev
    }, validation)

const validateItemNMFC = (item, validation = {}) => {
    ;["nmfc1", "nmfc2"].forEach(key => {
        if (item[key] && !/^\d+$/.test(item[key])) {
            validation[key] = fieldMustBeNumber(key.toUpperCase())
        }
    })
    if (item.nmfc2 && !item.nmfc1) {
        validation.nmfc1 = fieldRequired("NMFC1")
    }
    return validation
}

const validateItemHazmat = (item, validation = {}) => {
    if (!item.isHazMat) return validation
    if (!/^[0-9]{4}$/.test(item.unNumber)) {
        validation.unNumber = hazMatUNLength
    }
    if (!item.hazClass) validation.hazClass = fieldRequired(messages.hazClass)
    if (!item.pkgGrp) validation.pkgGrp = fieldRequired(messages.packageGroup)
    return validation
}

const validateItemHandlingUnits = (item, validation = {}) => {
    const { count } = item
    if (Number(count) === 0)
        validation.count = numberGreaterThanZero(messages.handlingUnits)
    return validation
}

const validateItemDimensions = (item, validation = {}) => {
    const { width, height, length } = item
    if (width && width > 93) validation.width = fieldOverLimit(messages.width)
    if (Number(width) === 0)
        validation.width = numberGreaterThanZero(messages.width)
    if (Number(length) === 0)
        validation.length = numberGreaterThanZero(messages.length)
    if (Number(height) === 0)
        validation.height = numberGreaterThanZero(messages.height)
    if (height && height > 106)
        validation.height = fieldOverLimit(messages.height)
    return validation
}

export const validateOverLength = (state, validation = {}) => {
    const { items, isExtremeLength, isOverLength } = state
    if (!items) return validation
    const maxLength = Math.max(...items.map(item => item.length))
    if (maxLength >= 144 && !isExtremeLength) {
        validation.isExtremeLength = shipmentExtremeLength
    } else if (maxLength >= 96 && maxLength < 144 && !isOverLength) {
        validation.isOverLength = shipmentExceedLength
    }
    return validation
}

export const validatePickupTime = () => async () => {}

export const validateWeightLimits = (state, validation = {}) => {
    const { items, mode } = state
    if (!items) return validation
    const totalWeight = items.reduce(
        (prev, item) => prev + Number(item.weight),
        0
    )
    if (mode === "LTL" && totalWeight > 19999) {
        validation.totalWeight = maximumWeightExceededLTL
    } else if (mode === "VOLUME_LTL" && totalWeight > 44000) {
        validation.totalWeight = maximumWeightExceededVLTL
    }
    return validation
}

export const validateItem = (
    item,
    { basic = true, volume = true, nmfc = true, hazmat = true } = {}
) => ({
    ...(basic &&
        (volume
            ? validateRequired(item, itemRequiredFields)
            : validateRequired(item, nonVolumeItemRequiredFields))),
    ...validateItemDimensions(item),
    ...validateItemHandlingUnits(item),
    ...validateFieldLength(item, itemFieldLengths),
    ...(hazmat && validateItemHazmat(item)),
    ...(nmfc && validateItemNMFC(item)),
})

export const isValidPhoneNumber = (phoneNumber, country) =>
    isValidNumber(parseNumber(phoneNumber, country))

export const isValidEmail = value =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(value)

const usZipValidation = /^\d{5}(-\d{4})?$/
const mxZipValidation = /^\d{5}$/
const canadaPostalCodeRegex = /^([A-Za-z]\d[A-Za-z])[- ]?(\d[A-Za-z]\d)$/
export const usDomesticOffshoreStates = ["AK", "PR", "HI"]
const usDomesticOffshoreStatesWithItemCustoms = ["PR"]

export const constructPostalCode = (value, country) => {
    if (country === "CA") {
        const groups = canadaPostalCodeRegex.exec(value)
        if (groups) {
            return (`${groups[1]} ${groups[2]}` ?? "").toUpperCase()
        }
        return (value ? value.toString() : "").toUpperCase()
    }
    return value
}

export const isValidUSPostalCode = value => value && usZipValidation.test(value)
export const isValidMXPostalCode = value => value && mxZipValidation.test(value)
export const isValidCountryPostalCode = (value, country) => {
    if (!value || !country) {
        return false
    }
    switch (country.toUpperCase()) {
        case "US":
            return isValidUSPostalCode(value)
        case "CA":
            return isValidCanadaPostalCode(value)
        case "MX":
            return isValidMXPostalCode(value)
        default:
            return false
    }
}
export const isValidCanadaPostalCode = value =>
    value && canadaPostalCodeRegex.test(value)

export const isUSDomesticShipment = (originCountry, destinationCountry) =>
    originCountry === "US" && destinationCountry === "US"

export const isMXDomesticShipment = (originCountry, destinationCountry) =>
    originCountry === "MX" && destinationCountry === "MX"

const invalidInbondCountries = ["MX"]

export const invalidInbondShipment = (originCountry, destinationCountry) =>
    invalidInbondCountries.includes(originCountry) ||
    invalidInbondCountries.includes(destinationCountry)

export const isUSDomesticOffshoreShipment = (
    originCountry,
    destinationCountry,
    originState,
    destinationState
) =>
    isUSDomesticShipment(originCountry, destinationCountry) &&
    originState !== destinationState &&
    (usDomesticOffshoreStates.includes(originState) ||
        usDomesticOffshoreStates.includes(destinationState))

export const isCanadaAlaska = (
    originState,
    originCountry,
    destinationState,
    destinationCountry
) =>
    (originCountry === "CA" &&
        destinationState === "AK" &&
        destinationCountry === "US") ||
    (destinationCountry === "CA" &&
        originState === "AK" &&
        originCountry === "US")

export const isUSDomesticOffshoreShipmentWithItemCustoms = (
    originCountry,
    destinationCountry,
    originState,
    destinationState
) =>
    isUSDomesticShipment(originCountry, destinationCountry) &&
    originState !== destinationState &&
    (usDomesticOffshoreStates.includes(originState) ||
        usDomesticOffshoreStatesWithItemCustoms.includes(destinationState))

export const isValidCountryForFedexShipment = country =>
    ["CA", "US"].includes(country)

export const isOffshoreCalculatedFedexRate = (
    originCountry,
    destinationCountry,
    originState,
    destinationState,
    selectedRate,
    domesticOffshoreRatingFlag,
    domesticOffshoreAKRating,
    domesticOffshoreHIRating
) => {
    //PR check
    let prCheck =
        isValidCountryForFedexShipment(originCountry) &&
        isValidCountryForFedexShipment(destinationCountry) &&
        (originState === "PR" || destinationState === "PR") &&
        !(originState === "PR" && destinationState === "PR") &&
        domesticOffshoreRatingFlag

    //AK check
    let akCheck =
        isValidCountryForFedexShipment(originCountry) &&
        isValidCountryForFedexShipment(destinationCountry) &&
        (originState === "AK" || destinationState === "AK") &&
        !(originState === "AK" && destinationState === "AK") &&
        domesticOffshoreAKRating

    //HI check
    let hiCheck =
        (originState === "HI" || destinationState === "HI") &&
        !(originState === "HI" && destinationState === "HI") &&
        isUSDomesticShipment(originCountry, destinationCountry) &&
        domesticOffshoreHIRating

    return (
        (containsCombinedOtherRatesAndFedexOceanRates(selectedRate) ||
            isFedExCarrier(selectedRate?.carrierCode)) &&
        (prCheck || akCheck || hiCheck)
    )
}

export const isInternationalShipment = (originCountry, destinationCountry) =>
    originCountry !== destinationCountry

export const isConsigneeImporter = importerIsNotConsignee =>
    !importerIsNotConsignee

export const isSpecifiedProducer = producer =>
    producer === "SINGLE_SPECIFIED" || producer === "MULTIPLE_SPECIFIED"

export const hasTheShipmentDestinationOrOriginCountry = (
    originCountry,
    destinationCountry,
    country
) => originCountry === country || destinationCountry === country

export const requiredNaftaDeclaration = naftaDeclaration => naftaDeclaration

export const isMexicoToUsaInternationalShipment = (
    originCountry,
    destinationCountry
) => destinationCountry === "US" && originCountry === "MX"

export const isUsaMainlandToMexicoInternationalShipment = (
    originCountry,
    destinationCountry,
    originState
) =>
    isInternationalShipment(originCountry, destinationCountry) &&
    originCountry === "US" &&
    destinationCountry === "MX" &&
    !usDomesticOffshoreStates.includes(originState)

export const isToOrFromMexicoShipment = (originCountry, destinationCountry) => {
    return originCountry === "MX" || destinationCountry === "MX"
}

export function fieldValidation(form, validation, strict) {
    return dispatch => {
        dispatch({ type: FIELD_VALIDATION, form, validation, strict })
        return !Object.keys(validation).length
    }
}

export function validWindowSize(startDate, endDate, sizeLimit, precision) {
    const timeWindowSize = endDate.diff(startDate, precision)
    return timeWindowSize >= sizeLimit
}

export function validateUserCloseTime(state, validation = {}) {
    const { readyTime, closeTime } = state
    const startDate = moment(readyTime, "h:mma")
    const endDate = moment(closeTime, "h:mma")
    if (!validWindowSize(startDate, endDate, 90, "minutes")) {
        return readyAndCloseTimeApartValue
    }
    return undefined
}

export function validateBookCloseTime(state, validation = {}) {
    const { endDate, startDate, timeZone } = state
    if (!validWindowSize(startDate, endDate, 90, "minutes")) {
        validation.closeTime = readyAndCloseTimeApartValue
        return validation
    }
    const closeTimeString = endDate.format("YYYY-MM-DD HH:mm:ss")
    const localCloseTime = moment.tz(closeTimeString, timeZone)
    const diffFromNow = localCloseTime.diff(moment.utc(), "minutes")
    if (diffFromNow < 0) {
        validation.closeTime = originCloseTimePast
    } else if (diffFromNow < 90) {
        validation.closeTime = originCloseTooEarly
    }
    return validation
}

export const scrolledToValidation = () => ({ type: SCROLLED_TO_VALIDATION })

export const resetValidationScroll = () => ({ type: RESET_VALIDATION_SCROLL })

export const isEmptyDeep = obj => {
    if (isObject(obj)) {
        if (Object.keys(obj).length === 0) return true
        return every(map(obj, v => isEmptyDeep(v)))
    } else if (isString(obj)) {
        return !obj.length
    }
    return false
}

export const hasHazMatIdPrefix = () =>
    createValidator(
        message => value =>
            value &&
            ["UN", "NA", "ID"].filter(prefix => value.startsWith(prefix))
                .length <= 0
                ? message
                : undefined,
        field => ({ ...messages.hasHazMatIdPrefix, values: { field } })
    )

export const pickUpDateValidator = isFreightDirectReturnsField =>
    createValidator(
        message => (fieldValue, formValues) => {
            const isFreightDirectReturns = get(
                formValues,
                isFreightDirectReturnsField,
                false
            )

            const minDate = moment
                .utc(
                    moment()
                        .tz("Pacific/Honolulu")
                        .format("YYYY-MM-DD")
                )
                .add(1, "days")

            const maxDate = moment
                .utc(
                    moment()
                        .tz("Pacific/Honolulu")
                        .format("YYYY-MM-DD")
                )
                .add(7, "days")

            const isInValidReturnPickUpDate =
                isFreightDirectReturns &&
                (fieldValue.isBefore(minDate) || fieldValue.isAfter(maxDate))

            if (
                !fieldValue ||
                !isFreightDirectReturns ||
                !isInValidReturnPickUpDate
            ) {
                return undefined
            }
            return message
        },
        shipDateInvalid
    )

export const customReferencesValidation = field =>
    createValidator(
        message => (fieldValue, formValues) => {
            const selectedLocation = get(formValues, "selectedLocation", {})

            let required = false
            switch (field) {
                case "po":
                    required =
                        selectedLocation?.shipmentRestrictions?.requirePoNumber
                    break
                case "refId":
                    required =
                        selectedLocation?.shipmentRestrictions?.requireRefId
                    break
                default:
                    break
            }

            if (!required || (required && fieldValue)) {
                return undefined
            }
            return message
        },
        customReferenceInvalid
    )

export const isAlphaNumericOnly = createValidator(
    message => value => (value && !/^[\w]+$/.test(value) ? message : undefined),
    field => ({ ...messages.alphaNumeric, values: { field } })
)
